export type ProviderType = "MAPBOX" | "WAYBACK"
export const isProviderValid = (provider: string): provider is ProviderType => {
  return provider === "MAPBOX" || provider === "WAYBACK"
}

export type Provider = {
  mapbox: string
  wayback: string
}
export type ProviderDTO = {
  MAPBOX: string
  WAYBACK: string
}

export const providerFromDto = (dto: ProviderDTO): Provider => {
  return { mapbox: dto.MAPBOX, wayback: dto.WAYBACK }
}

export const initProvider = (): Provider => {
  return { mapbox: "", wayback: "" }
}
