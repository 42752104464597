import {
  decodeThumbnail,
  DeleteFilled,
  getDateLabel,
  getFormattedGsd,
  Loading,
  ReportFilled,
  Scene,
} from "@ovision-gis-frontend/shared"
import { IconButton, Thumbnail, Tooltip } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import ContextMenuPopover from "../service-layout/ContextMenuPopover"
import styles from "./ImageItem.module.scss"

type Props = {
  className?: string
  data: Scene
  active?: boolean
  selected?: boolean
  disabled?: boolean
  onImageClick?: (scene: Scene) => void
  onDeleteClick?: (scene: Scene) => void
  onUnselectClick?: React.MouseEventHandler<HTMLElement>
}

function ImageItem(props: Props) {
  const { t } = useTranslation()

  const getStatusLabel = () => {
    const _status = props.data.status
    if (_status === "UPLOADING") return t("myImages.status.processing.uploading.label")
    else if (_status === "UPLOADED") return t("myImages.status.processing.uploaded.label")
    else if (_status === "TILING") return t("myImages.status.processing.tiling.label")
    else if (_status === "INVALID") return t("myImages.status.error.invalid.label")
    else if (_status === "DELETED") return t("myImages.status.error.deleted.label")
    else if (_status === "ERROR") return t("myImages.status.error.error.label")
    else return ""
  }
  const renderStatus = () => {
    const _tooltipLabel = getStatusLabel()
    switch (props.data.status) {
      case "UPLOADING":
      case "UPLOADED":
      case "TILING":
        return (
          <Tooltip size={"small"} title={_tooltipLabel} placement={"bottomRight"} point={true}>
            <Loading size={"xs"} />
          </Tooltip>
        )
      case "INVALID":
      case "DELETED":
      case "ERROR":
        return (
          <Tooltip size={"small"} title={_tooltipLabel} placement={"bottomRight"} point={true}>
            <ReportFilled />
          </Tooltip>
        )
      case "COMPLETED":
      default:
        return <></>
    }
  }

  return (
    <div
      className={cn(styles.imageItem, props.active && styles.active, props.disabled && styles.disabled, props.className)}
      onClick={props.disabled ? undefined : () => props.onImageClick?.(props.data)}
    >
      <div className={styles.highlight} />
      <Thumbnail className={styles.thumbnail} alt={"THUMBNAIL"} src={decodeThumbnail(props.data.thumbnail)} />
      <div className={styles.contents}>
        <div className={styles.titleContainer}>
          <Tooltip size={"large"} title={props.data.name} placement={"bottom"} point={true}>
            <span className={styles.title}>{props.data.name || "-"}</span>
          </Tooltip>
          <span className={styles.statusIcon}>
            {!props.selected ? (
              renderStatus()
            ) : (
              <IconButton size={"xs"} type={"square"} icon={<DeleteFilled />} onClick={props.onUnselectClick} />
            )}
          </span>
        </div>
        <span className={styles.region}>{`${props.data.country || "-"} / ${props.data.city || "-"} / ${
          props.data.continent || "-"
        }`}</span>

        <span className={styles.satellite}>
          {(props.data.satellite || "-") + " • " + getFormattedGsd(props.data.gsdX, props.data.gsdY)}
        </span>
        <span className={styles.date}>{getDateLabel(props.data.imagingTime) || "-"}</span>
        <ContextMenuPopover
          className={styles.contextMenu}
          containerClassName={styles.contextPopoverContainer}
          positionClassName={styles.positionClassName}
          contents={[
            {
              text: t("popover.contextMenu.delete"),
              danger: true,
              onClick: () => props.onDeleteClick?.(props.data),
            },
          ]}
          button={{ className: styles.contextMenuBtn }}
        />
      </div>
    </div>
  )
}
export default ImageItem
