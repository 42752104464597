import { Toast } from "@SIAnalytics/ovision-design-system"
import i18next from "i18next"
import { Map as OlMap } from "ol"

import { WMSCapabilitiesJSON } from "../../../common/wmsCapabilitiesType"
import { Layer, LayerOpacityMap } from "../layer-control-panel/LayerItems"
import WeatheOLayer, { CapabilitiesLayerNameType } from "./WeatheOLayer"
import { gsMapBaseUrl } from "./weatherMapUtil"

class WeatheOLayerGroup {
  private layers
  public capabilities: WMSCapabilitiesJSON | undefined

  constructor(layers: WeatheOLayer[]) {
    this.layers = layers
  }

  static dateToLayer(date: Date) {
    const utcYear = date.getUTCFullYear()
    const utcMonth = (date.getUTCMonth() + 1).toString().padStart(2, "0")
    const utcDate = date.getUTCDate().toString().padStart(2, "0")
    const utcHour = date.getUTCHours().toString().padStart(2, "0")
    //분 뒷자리 0으로 셋팅
    const utcMinutes = (Math.floor(date.getUTCMinutes() / 10) * 10).toString().padStart(2, "0")
    return `${utcYear}${utcMonth}${utcDate}${utcHour}${utcMinutes}`
  }

  static findLayerFromCapabilities(
    time: string,
    name: CapabilitiesLayerNameType,
    capabilities: WMSCapabilitiesJSON | undefined,
  ) {
    if (capabilities === undefined) return
    const layers = capabilities["Capability"]["Layer"]["Layer"]
    return layers.find((layer) => layer["Name"].includes(name) && layer["Name"].includes(time))
  }

  updateActives(activeLayers: Layer[], date: Date, map: OlMap) {
    if (!this.capabilities) return

    const time = WeatheOLayerGroup.dateToLayer(date)

    this.layers.forEach((layer) => {
      const isActive = activeLayers.find((layerName) => layerName === layer.getName) !== undefined
      layer.isActive = isActive
      const capabilitiesLayer = WeatheOLayerGroup.findLayerFromCapabilities(
        time,
        layer.getCapabilitiesName,
        this.capabilities,
      )
      if (isActive && capabilitiesLayer) layer.addLayer(map)
      else layer.removeLayer(map)
    })
  }

  updateOpacities(opacities: LayerOpacityMap) {
    this.layers.forEach((layer) => {
      const opacity = opacities.get(layer.getName)
      if (opacity) layer.updateOpacity(opacity)
    })
  }

  updateTime(date: Date, map: OlMap, isPlay: boolean) {
    if (!this.capabilities) return

    const time = WeatheOLayerGroup.dateToLayer(date)

    this.layers.forEach((layer) => {
      const capabilitiesLayer = WeatheOLayerGroup.findLayerFromCapabilities(
        time,
        layer.getCapabilitiesName,
        this.capabilities,
      )

      if (capabilitiesLayer) {
        const layerName = capabilitiesLayer["Name"]
        layer.updateLayer(layerName)
        if (layer.isActive) layer.addLayer(map)
      } else if (!isPlay) {
        if (layer.isActive) Toast({ type: "error", message: i18next.t("toast.weatheo.error") })
        layer.removeLayer(map)
      }
    })
  }

  getLayer(layerName: Layer) {
    return this.layers.find((layer) => layer.getName === layerName)
  }

  static checkGsMapEmpty = (date: Date, gsmapEmptyMap: Map<string, boolean>): boolean => {
    if (gsmapEmptyMap === undefined) return false
    return gsmapEmptyMap.get(makeGsmapUrl(date)) ?? false
  }

  static checkActiveLayerEmpty = async (
    activeLayers: Layer[],
    date: Date,
    capabilities: WMSCapabilitiesJSON,
    gsmapEmptyMap: Map<string, boolean>,
  ) => {
    const time = this.dateToLayer(date)

    for (const layerName of activeLayers) {
      if (layerName === "GSMAP") {
        if (this.checkGsMapEmpty(date, gsmapEmptyMap)) return true
      } else {
        const capabilitiesName = this.layerNameToCapabilitiesName(layerName)
        const capabilitiesLayer = this.findLayerFromCapabilities(time, capabilitiesName, capabilities)

        if (capabilitiesLayer?.Name) return true
      }
    }

    return false
  }

  static layerNameToCapabilitiesName = (layerName: Layer): CapabilitiesLayerNameType => {
    switch (layerName) {
      case "WEATHEO_RAIN":
        return "GEORAIN_INF"
      case "INFRARED_IMAGE":
        return "GEORAIN_IR"
      case "VISIBLE_IMAGE":
        return "GEORAIN_VI"
      default:
        throw Error()
    }
  }
}

export default WeatheOLayerGroup

const makeGsmapUrl = (date: Date) => {
  const minute = date.getUTCMinutes() >= 30 ? 30 : 0
  return `${gsMapBaseUrl}?prod=rain&year=${date.getUTCFullYear()}&month=${
    date.getUTCMonth() + 1
  }&day=${date.getUTCDate()}&hour=${date.getUTCHours()}&min=${minute}&z=0&x=0&y=0`
}
