import { patchSceneStatus, postSceneUpload, uploadBySignedUrl } from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { Button, Toast } from "@SIAnalytics/ovision-design-system"
// @ts-ignore
import loam from "loam"
import proj4 from "proj4"
import React, { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { useTranslation } from "react-i18next"

const defs = require("./crs-defs.json")
Object.keys(defs).forEach((key) => {
  defs["urn:ogc:def:crs:" + key.replace(":", "::")] = defs[key]
})

type Props = {
  onUploadStarted?: () => void
}

export default function ImageUpload(props: Props) {
  const { t } = useTranslation()

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      loam.initialize("https://unpkg.com/loam@1.2.0/lib/", "https://unpkg.com/gdal-js@2.2.0/")
      acceptedFiles.forEach((file, index) => {
        const result = loam
          .open(file)
          .then((dataset: any) => {
            return dataset.wkt().then((data: string) => {
              try {
                // proj4 파라미터로 인식할 수 있는지 시도
                proj4.Proj(data)
                return true
              } catch (e: any) {
                const res = data.replaceAll("[", ",").replaceAll("]", ",").replaceAll('"', ",").split(",")
                const numList = res.filter((x) => !isNaN(parseInt(x)))
                const crsNum = numList[numList.length - 1]
                return defs["EPSG:" + crsNum.toString()] !== undefined
              }
            })
          })
          .catch((reason: any) => {
            captureException(reason)
            return false
          })
        result.then(async (isValidImage: boolean) => {
          if (!isValidImage) {
            Toast({ message: t("toast.imageUpload.error"), type: "error" })
            return
          }

          try {
            if (index === 0) props.onUploadStarted?.()
            await uploadToBucket(file)
          } catch (e) {
            Toast({ message: t("toast.imageUpload.error"), type: "error" })
            captureException(e)
          }
        })
      })
    },
    [t],
  )

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noDrag: true,
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: {
      "image/tiff": [],
    },
  })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Button size={"small"} onClick={open}>
        {t("button.addImage")}
      </Button>
    </div>
  )
}

const uploadToBucket = async (file: File) => {
  const { signedUrl, hashName } = await postSceneUpload(file.name, file.size.toString())
  try {
    await uploadBySignedUrl(signedUrl, file, "application/octet-stream")
    await patchSceneStatus(hashName, "COMPLETED")
  } catch (e) {
    await patchSceneStatus(hashName, "FAILED")
    throw e
  }
}
