import {
  MyLocationOutlined,
  generateOlMap,
  LAYER_TILE_OSM,
  LAYER_TILE_OSM_Z_INDEX,
  LAYER_VI_Z_INDEX,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { Toast, VerticalButtonGroup } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import { Map as OlMap, View } from "ol"
import { defaults as defaultControls, MousePosition, ScaleLine } from "ol/control"
import { format as formatCoord } from "ol/coordinate"
import { GeoJSON } from "ol/format"
import TileLayer from "ol/layer/Tile"
import VectorLayer from "ol/layer/Vector"
import { fromLonLat, transformExtent } from "ol/proj"
import { OSM, XYZ } from "ol/source"
import VectorSource from "ol/source/Vector"
import { Stroke, Style } from "ol/style"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { WMSCapabilitiesJSON } from "../../common/wmsCapabilitiesType"
import SearchMap from "../analysis/SearchMap"
import type { LayerOpacityMap, Layer as WeatherLayer } from "./layer-control-panel/LayerItems"
import { MapThemes } from "./map-theme-button/MapThemeButton"
import WeatheOLayer from "./weather-map/WeatheOLayer"
import WeatheOLayerGroup from "./weather-map/WeatheOLayerGroup"
import {
  generateIRLayer,
  generateVILayer,
  generateWeatheORainLayer,
  getTileLayerContext,
  getGsmapUrl,
  updateGSMap,
} from "./weather-map/weatherMapUtil"
import styles from "./WeatherMap.module.scss"

type Props = {
  className?: string
  mapTheme: MapThemes
  layerOpacities: LayerOpacityMap
  activeLayers: WeatherLayer[]
  date: Date
  isPlay: boolean
  capabilities: WMSCapabilitiesJSON | undefined
  gsmapEmptyMap: Map<string, boolean>
}

function WeatherMap(props: Props) {
  const [isToastPopup, setIsToastPopup] = useState<boolean>(false)
  const [haveToPopupToast, setHaveToPopupToast] = useState<boolean>(true)

  const mapContainerRef = useRef<HTMLDivElement>(null)
  const mapRef = useRef<OlMap | null>(null)
  const scaleLineRef = useRef<HTMLDivElement>(null)
  const mousePositionRef = useRef<HTMLDivElement>(null)
  const osmSource = useRef(new OSM({ attributions: [], wrapX: true }))
  const tileLayer = useRef(
    new TileLayer({
      className: LAYER_TILE_OSM,
      properties: { name: LAYER_TILE_OSM },
      zIndex: LAYER_TILE_OSM_Z_INDEX,
      source: osmSource.current,
    }),
  )
  const mapboxSource = useRef(
    new XYZ({
      url: `https://api.mapbox.com/styles/v1/sianalytics/clb5za3o3000d15lb7bt7nayt/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`,
      crossOrigin: "anonymous",
    }),
  )
  const gsMapLayer = useRef(
    new TileLayer({
      source: new XYZ({
        url: getGsmapUrl(props.date),
      }),
      zIndex: 15, //추후 Drag & Drop 기능으로 Z index 변경하여야함
    }),
  )

  const weatheORainLayer = useRef(
    new WeatheOLayer(
      generateWeatheORainLayer("", props.layerOpacities.get("WEATHEO_RAIN")),
      "WEATHEO_RAIN",
      "GEORAIN_INF",
      props.activeLayers.includes("WEATHEO_RAIN"),
    ),
  )
  const viLayer = useRef(
    new WeatheOLayer(
      generateVILayer("", props.layerOpacities.get("VISIBLE_IMAGE")),
      "VISIBLE_IMAGE",
      "GEORAIN_VI",
      props.activeLayers.includes("VISIBLE_IMAGE"),
    ),
  )
  const irLayer = useRef(
    new WeatheOLayer(
      generateIRLayer("", props.layerOpacities.get("INFRARED_IMAGE")),
      "INFRARED_IMAGE",
      "GEORAIN_IR",
      props.activeLayers.includes("INFRARED_IMAGE"),
    ),
  )
  const layerGroup = useRef(new WeatheOLayerGroup([weatheORainLayer.current, viLayer.current, irLayer.current]))

  const coastline = useRef(
    new VectorLayer({
      className: "coastline",
      extent: [4851237.254910121, -7648187.200039871, 23072718.146954816, 9862141.296925904],
      source: new VectorSource({
        format: new GeoJSON(),
        url: "../countries-coastline-5km.geo.json",
      }),
      style: new Style({
        stroke: new Stroke({
          color: "yellow",
          width: 1,
        }),
      }),
      visible: false,
      zIndex: LAYER_VI_Z_INDEX + 1,
    }),
  )

  const { t } = useTranslation()

  useEffect(() => {
    layerGroup.current.capabilities = props.capabilities
  }, [props.capabilities])

  useEffect(() => {
    if (!mapRef.current) return
    const gsMapOpacity = props.layerOpacities.get("GSMAP")

    gsMapLayer.current.setOpacity(gsMapOpacity ? gsMapOpacity : 0)
    layerGroup.current.updateOpacities(props.layerOpacities)
  }, [props.layerOpacities])

  useEffect(() => {
    if (mapRef.current) return
    if (!mapContainerRef.current || !mapRef || !scaleLineRef.current) return

    const scaleLine = new ScaleLine({
      className: styles.olScaleLine,
      target: scaleLineRef.current,
    })

    const mousePosition = new MousePosition({
      coordinateFormat: (coordinate) => {
        return coordinate ? formatCoord(coordinate, "{y}, {x}", 3) : ""
      },
      projection: "EPSG:4326",
      className: styles.mousePosition,
      target: mousePositionRef.current || undefined,
    })

    mapRef.current = generateOlMap(
      [tileLayer.current, coastline.current],
      mapContainerRef.current,
      new View({ maxZoom: 7 }),
      defaultControls({ rotate: false }).extend([scaleLine, mousePosition]),
    )

    mapRef.current
      .getView()
      .fit(
        transformExtent(
          [91.01493155415875, -15.00078402053903, 174.1725206940971, 49.767977251851306],
          "EPSG:4326",
          "EPSG:3857",
        ),
      )

    return () => {
      scaleLine.setMap(null)
      mousePosition.setMap(null)
      mapRef.current?.getAllLayers().map((_layer) => mapRef.current?.removeLayer(_layer))
      mapRef.current?.setTarget(undefined)
      mapRef.current = null
    }
  }, [])

  useEffect(() => {
    if (!mapRef.current) return

    updateGSMap(props.activeLayers, mapRef.current, gsMapLayer.current, props.date, props.gsmapEmptyMap)
  }, [props.date, props.activeLayers])

  useEffect(() => {
    if (!mapRef.current) return
    layerGroup.current.updateTime(props.date, mapRef.current, props.isPlay)
  }, [props.date])

  useEffect(() => {
    if (!mapRef.current) return
    layerGroup.current.updateActives(props.activeLayers, props.date, mapRef.current)
    if (props.activeLayers.includes("INFRARED_IMAGE") || props.activeLayers.includes("VISIBLE_IMAGE"))
      coastline.current.setVisible(true)
    else coastline.current.setVisible(false)
  }, [props.activeLayers])

  useEffect(() => {
    if (!props.activeLayers.includes("VISIBLE_IMAGE")) setHaveToPopupToast(true)
  }, [props.activeLayers])

  useEffect(() => {
    if (
      props.activeLayers.includes("VISIBLE_IMAGE") &&
      (props.date.getHours() < 9 || props.date.getHours() >= 16) &&
      !isToastPopup &&
      haveToPopupToast
    ) {
      setIsToastPopup(true)
      setHaveToPopupToast(false)
      Toast({
        message: t("tooltip.visibleImage"),
        type: "warning",
        closable: true,
        onClose: () => setIsToastPopup(false),
      })
    }
  }, [props.date, props.activeLayers])

  useEffect(() => {
    const changeTheme = async () => {
      const context = await getTileLayerContext(tileLayer.current)
      if (props.mapTheme === "OSM") {
        context.canvas.style.filter = ""
        tileLayer.current.setSource(osmSource.current)
      } else if (props.mapTheme === "DarkOSM") {
        context.canvas.style.filter =
          "brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7)"
        tileLayer.current.setSource(osmSource.current)
      } else if (props.mapTheme === "Satellite") {
        context.canvas.style.filter = ""
        tileLayer.current.setSource(mapboxSource.current)
      }
    }

    changeTheme()
  }, [props.mapTheme])

  const handleMyLocationButtonClick = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { longitude, latitude } }) => {
          mapRef.current?.getView().setCenter(fromLonLat([longitude, latitude]))
          mapRef.current?.getView().setZoom(14)
        },
        (error) => captureException(error),
      )
    }
  }

  const handleCenterChange = (center: number[]) => {
    if (!mapRef.current || center.length === 0) return
    mapRef.current.getView().setCenter(fromLonLat(center))
    mapRef.current.getView().setZoom(14)
  }

  return (
    <div className={styles.baseMap}>
      <div className={cn(styles.mapContainer, props.className)} ref={mapContainerRef}>
        <SearchMap setCenter={handleCenterChange} />
        <VerticalButtonGroup
          groupClassName={styles.myLocation}
          size={"large"}
          options={[
            {
              label: t("button.myLocation") ?? "",
              icon: <MyLocationOutlined />,
              value: t("button.myLocation") ?? "",
              onClick: handleMyLocationButtonClick,
            },
          ]}
        />
        <div className={styles.customScaleLine}>
          <div className={styles.tileSupplier}>
            <span>©Open Street Map</span>
            <span>©Mapbox</span>
          </div>
          <div ref={mousePositionRef} />
          <div ref={scaleLineRef} />
        </div>
      </div>
    </div>
  )
}
export default WeatherMap
