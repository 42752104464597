import React from "react"

import { IconBase, IconProps } from "../../IconBase"

export function PlayArrowFilled(props: IconProps) {
  return (
    <IconBase viewBox={"0 0 32 32"} {...props}>
      <path d={"M8.66675 6.66667V25.3333L23.3334 16L8.66675 6.66667Z"} fill={"#616161"} />
    </IconBase>
  )
}
