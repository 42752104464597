import { Dropdown, InputField } from "@SIAnalytics/ovision-design-system"
import { ItemOption } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/context-menu/ContextMenu"
import { InputFieldBasicStateType } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/text-input/input-field/InputField"
import { countries, getCountryCode, getCountryData, ICountry, TCountryCode } from "countries-list"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./ContactNumberForm.module.scss"

type Props = {
  countryCode: TCountryCode | ""
  setCountryCode: React.Dispatch<React.SetStateAction<"" | TCountryCode>>
  countryNative: string
  setCountryNative: React.Dispatch<React.SetStateAction<string>>
  countryPhone: string
  setCountryPhone: React.Dispatch<React.SetStateAction<string>>
  contactNumber: string
  setContactNumber: React.Dispatch<React.SetStateAction<string>>
  isContactNumberValid: InputFieldBasicStateType
  setIsContactNumberValid: React.Dispatch<React.SetStateAction<InputFieldBasicStateType>>
  tabIndex?: number
  autoFocus?: boolean
}

function ContactNumberForm(props: Props) {
  const [countryDatas, setCountryDatas] = useState<[TCountryCode, ICountry][]>([])
  const [countryDropdownItems, setCountryDropdownItems] = useState<ItemOption[]>([])

  const { t } = useTranslation()

  const countryDropdownItemsMemo: ItemOption[] = useMemo(() => {
    setCountryDatas(Object.entries(countries) as [TCountryCode, ICountry][])
    return Object.entries(countries).map((_data) => {
      return { text: `${_data[1].native} +${_data[1].phone[0]}`, value: `${_data[1].native} +${_data[1].phone[0]}` }
    })
  }, [])

  useEffect(() => {
    setCountryDropdownItems(countryDropdownItemsMemo)

    const _data = countryDatas.filter((_country) => navigator.language.includes(_country[0]))
    if (_data.length) {
      props.setCountryCode(_data[0][0])
      props.setCountryNative(_data[0][1].native)
      props.setCountryPhone(_data[0][1].phone[0].toString())
    }
  }, [countryDatas, countryDropdownItemsMemo])

  return (
    <div className={styles.contactNumberForm}>
      <div className={"ods-form-item"}>
        <p>{t("textInput.contactNumber")}</p>
        <Dropdown
          type={"single"}
          itemList={countryDropdownItems}
          placeholder={t("dropdown.placeholder.title", { target: t("textInput.countryCode.title").toLowerCase() }) ?? ""}
          search={true}
          searchPlaceholder={t("dropdown.searchPlaceholder.title") ?? ""}
          value={`${props.countryNative} +${props.countryPhone}`}
          onChange={(e) => {
            const _native = (e as string).split(" +")[0]
            const _code = getCountryCode(_native)
            if (_code) {
              const _data = getCountryData(_code)
              props.setCountryCode(_code)
              props.setCountryNative(_data.native)
              props.setCountryPhone(_data.phone[0].toString())
            }
          }}
        />
      </div>
      <InputField
        id={"contactNumber"}
        tabIndex={props.tabIndex}
        type={"tel"}
        autoComplete={"tel-national"}
        autoFocus={props.autoFocus}
        maxLength={13}
        pattern={"[0-9]{2,3}-[0-9]{3,4}-[0-9]{3,4}"}
        placeholder={t("textInput.placeholder", { target: t("textInput.contactNumber").toLowerCase() }) ?? ""}
        state={props.isContactNumberValid}
        value={props.contactNumber}
        onChange={(event) => {
          props.setIsContactNumberValid("default")
          const _value = event.target.value
          props.setContactNumber(_value.replace(/[^0-9]/g, "").replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`))
        }}
        onClearButtonClick={() => props.setContactNumber("")}
        onKeyPress={(e) => {
          if (!/[0-9]/.test(e.key)) e.preventDefault()
        }}
      />
    </div>
  )
}
export default ContactNumberForm
