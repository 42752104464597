import {
  AiPackInfo,
  AiPackInquiryRequestType,
  contactNumberReg,
  emailReg,
  isError,
  isInitialAiPackInfo,
  postAiPacksInquiry,
  MyAccount,
  getAiPack,
  Modal,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { Button, Checkbox, InfoOutlined, InputField, RadioGroup, Toast, Tooltip } from "@SIAnalytics/ovision-design-system"
import { InputFieldBasicStateType } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/text-input/input-field/InputField"
import cn from "classnames"
import { TCountryCode } from "countries-list"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import ContactNumberForm from "../pricing/ContactNumberForm"
import styles from "./AnalysisInquiryModal.module.scss"

type AmountOptionType = "ONE" | "TWO" | "THREE" | "FOUR" | "FIVE"

type Props = {
  className?: string
  selectedAiPackInfo: AiPackInfo
  user: MyAccount
  closeModal: () => void
}

function AnalysisInquiryModal(props: Props) {
  const [countryCode, setCountryCode] = useState<TCountryCode | "">("")
  const [countryNative, setCountryNative] = useState<string>("")
  const [countryPhone, setCountryPhone] = useState<string>("")
  const [contactNumber, setContactNumber] = useState<string>("")
  const [isContactNumberValid, setIsContactNumberValid] = useState<InputFieldBasicStateType>("default")

  const [email, setEmail] = useState<string>(props.user.account.profile.email ?? "")
  const [isEmailValid, setIsEmailValid] = useState<InputFieldBasicStateType>("default")
  const [dataAmount, setDataAmount] = useState<AmountOptionType>("ONE")
  const [dataSizeWidth, setDataSizeWidth] = useState<string>("")
  const [isDataSizeWidthValid, setIsDataSizeWidthValid] = useState<InputFieldBasicStateType>("default")
  const [dataSizeHeight, setDataSizeHeight] = useState<string>("")
  const [isDataSizeHeightValid, setIsDataSizeHeightValid] = useState<InputFieldBasicStateType>("default")
  const [checkPlanet, setCheckPlanet] = useState<boolean>(false)
  const [checkEtc, setCheckEtc] = useState<boolean>(false)
  const [etc, setEtc] = useState<string>("")

  const [isRequesting, setIsRequesting] = useState<boolean>(false)
  const { t } = useTranslation()

  const planetCheckboxRef = useRef<HTMLInputElement>(null)
  const etcCheckboxRef = useRef<HTMLInputElement>(null)
  const inquiryButtonRef = useRef<HTMLElement>(null)

  const isInquiryDisabled =
    !countryCode ||
    !countryPhone ||
    !contactNumber ||
    !email ||
    !dataAmount ||
    !dataSizeWidth ||
    !dataSizeHeight ||
    !(checkPlanet || checkEtc)

  const onInquiryButtonClick = () => {
    if (isInquiryDisabled || isInitialAiPackInfo(props.selectedAiPackInfo)) return

    let numberCheck = false
    let emailCheck = false
    let dataWidthCheck = false
    let dataHeightCheck = false

    if (contactNumberReg.test(contactNumber)) {
      setIsContactNumberValid("default")
      numberCheck = true
    } else {
      setIsContactNumberValid("error")
    }

    if (emailReg.test(email)) {
      setIsEmailValid("default")
      emailCheck = true
    } else {
      setIsEmailValid("error")
    }

    if (dataSizeWidth) {
      setIsDataSizeWidthValid("default")
      dataWidthCheck = true
    } else {
      setIsDataSizeWidthValid("error")
    }
    if (dataSizeHeight) {
      setIsDataSizeHeightValid("default")
      dataHeightCheck = true
    } else {
      setIsDataSizeHeightValid("error")
    }

    if (!numberCheck || !emailCheck || !dataWidthCheck || !dataHeightCheck) return

    const requestAiPacksInquiryAsync = async () => {
      setIsRequesting(true)

      const dataSize = dataSizeWidth + " X " + dataSizeHeight
      let satellite = ""
      if (checkPlanet && checkEtc) {
        satellite =
          t("modal.analysisInquiry.satellite.planetScope.label") +
          ", " +
          t("modal.analysisInquiry.satellite.etc.label") +
          ` (${etc})`
      } else if (checkPlanet) {
        satellite = t("modal.analysisInquiry.satellite.planetScope.label")
      } else if (checkEtc) {
        satellite = t("modal.analysisInquiry.satellite.etc.label")
      }

      const data: AiPackInquiryRequestType = {
        countryCode,
        contactNumber,
        businessEmail: email,
        dataAmount: getDataAmountLabel(),
        dataSize,
        satellite,
      }

      try {
        const _inquiry = await postAiPacksInquiry(props.selectedAiPackInfo.id, data)
        if (!isError(_inquiry) && _inquiry === 200) Toast({ message: t("toast.inquiry.success"), type: "success" })
      } catch (e) {
        Toast({ message: t("toast.inquiry.error"), type: "error" })
        captureException(e)
      } finally {
        setIsRequesting(false)
      }
      props.closeModal()
    }
    void requestAiPacksInquiryAsync()
  }

  const getDataAmountLabel = () => {
    switch (dataAmount) {
      case "ONE":
        return t("modal.analysisInquiry.amountOfData.option.one.label")
      case "TWO":
        return t("modal.analysisInquiry.amountOfData.option.two.label")
      case "THREE":
        return t("modal.analysisInquiry.amountOfData.option.three.label")
      case "FOUR":
        return t("modal.analysisInquiry.amountOfData.option.four.label")
      case "FIVE":
        return t("modal.analysisInquiry.amountOfData.option.five.label")
      default:
        return ""
    }
  }

  return (
    <Modal
      className={cn(styles.analysisInquiryModal, props.className)}
      title={[getAiPack(props.selectedAiPackInfo.type).label, t("modal.analysisInquiry.title")].join(" ").trimStart()}
      content={
        <>
          <p>{t("modal.analysisInquiry.desc")}</p>
          <form
            onKeyDown={(e) => {
              if ((e.code === "Enter" || e.code === "Space") && document.activeElement?.id === "planetCheckboxLabel")
                planetCheckboxRef.current?.click()
              else if ((e.code === "Enter" || e.code === "Space") && document.activeElement?.id === "etcCheckboxLabel")
                etcCheckboxRef.current?.click()
              else if (e.code === "Enter") inquiryButtonRef.current?.click()
            }}
            onSubmit={(e) => e.preventDefault()}
          >
            <ContactNumberForm
              tabIndex={1}
              autoFocus={true}
              contactNumber={contactNumber}
              countryCode={countryCode}
              countryNative={countryNative}
              countryPhone={countryPhone}
              isContactNumberValid={isContactNumberValid}
              setContactNumber={setContactNumber}
              setCountryCode={setCountryCode}
              setCountryNative={setCountryNative}
              setCountryPhone={setCountryPhone}
              setIsContactNumberValid={setIsContactNumberValid}
            />
            <div className={cn("ods-form-item", styles.formItem)}>
              <p>{t("textInput.email.label")}</p>
              <InputField
                id={"email"}
                tabIndex={2}
                type={"text"}
                autoComplete={"email"}
                placeholder={t("textInput.placeholder", { target: t("textInput.email.label").toLowerCase() }) ?? ""}
                state={isEmailValid}
                value={email}
                onChange={(e) => {
                  setIsEmailValid("default")
                  setEmail(e.target.value)
                }}
                onClearButtonClick={() => setEmail("")}
              />
            </div>
            <div className={cn("ods-form-item", styles.formItem)}>
              <p>{t("modal.analysisInquiry.amountOfData.label")}</p>
              <RadioGroup
                groupClassName={styles.radioGroup}
                type={"horizontal"}
                options={
                  [
                    { label: t("modal.analysisInquiry.amountOfData.option.one.label"), value: "ONE" },
                    { label: t("modal.analysisInquiry.amountOfData.option.two.label"), value: "TWO" },
                    { label: t("modal.analysisInquiry.amountOfData.option.three.label"), value: "THREE" },
                    { label: t("modal.analysisInquiry.amountOfData.option.four.label"), value: "FOUR" },
                    { label: t("modal.analysisInquiry.amountOfData.option.five.label"), value: "FIVE" },
                  ] as { label: string; value: AmountOptionType }[]
                }
                value={dataAmount}
                onChange={(value) => setDataAmount(value as AmountOptionType)}
              />
            </div>
            <div className={cn("ods-form-item", styles.formItem)}>
              <div className={styles.fieldLabel}>
                {t("modal.analysisInquiry.sizeOfData.label")}
                <Tooltip
                  size={"large"}
                  title={t("tooltip.modal.analysisInquiry.sizeOfData")}
                  placement={"bottom"}
                  point={true}
                >
                  <InfoOutlined />
                </Tooltip>
              </div>
              <div className={styles.horizontal}>
                <InputField
                  id={"pixelWidth"}
                  tabIndex={3}
                  type={"number"}
                  min={"0"}
                  placeholder={"00000"}
                  state={isDataSizeWidthValid}
                  onChange={(e) => {
                    setIsDataSizeWidthValid("default")
                    let _value = e.target.value
                    if (parseInt(_value) < 0) _value = "0"
                    setDataSizeWidth(_value)
                  }}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) e.preventDefault()
                  }}
                />
                <span>{"X"}</span>
                <InputField
                  id={"pixelHeight"}
                  tabIndex={4}
                  type={"number"}
                  min={"0"}
                  placeholder={"00000"}
                  state={isDataSizeHeightValid}
                  onChange={(e) => {
                    setIsDataSizeHeightValid("default")
                    let _value = e.target.value
                    if (parseInt(_value) < 0) _value = "0"
                    setDataSizeHeight(_value)
                  }}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) e.preventDefault()
                  }}
                />
                <span>{"px"}</span>
              </div>
            </div>
            <div className={cn("ods-form-item", styles.formItem)}>
              <p>{t("modal.analysisInquiry.satellite.label")}</p>
              <div className={styles.checkboxGroup}>
                <Checkbox
                  id={"planetCheckbox"}
                  ref={planetCheckboxRef}
                  tabIndex={5}
                  active={checkPlanet}
                  onClick={() => setCheckPlanet((prev) => !prev)}
                >
                  {t("modal.analysisInquiry.satellite.planetScope.label")}
                </Checkbox>
                <Checkbox
                  id={"etcCheckbox"}
                  ref={etcCheckboxRef}
                  tabIndex={6}
                  active={checkEtc}
                  onClick={() => setCheckEtc((prev) => !prev)}
                >
                  {t("modal.analysisInquiry.satellite.etc.label")}
                </Checkbox>
              </div>
              {checkEtc && (
                <InputField
                  id={"etc"}
                  tabIndex={7}
                  type={"text"}
                  placeholder={
                    t("textInput.placeholder", {
                      target: t("modal.analysisInquiry.satellite.etc.placeholder.label").toLowerCase(),
                    }) ?? ""
                  }
                  value={etc}
                  onChange={(e) => setEtc(e.target.value)}
                  onClearButtonClick={() => setEtc("")}
                />
              )}
            </div>
          </form>
        </>
      }
      footer={{
        right: (
          <>
            <Button size={"small"} type={"outlined"} htmlType={"button"} onClick={props.closeModal}>
              {t("button.cancel")}
            </Button>
            <Button
              ref={inquiryButtonRef}
              tabIndex={checkEtc ? 8 : 7}
              size={"small"}
              disabled={isInquiryDisabled}
              htmlType={"submit"}
              loading={isRequesting}
              onClick={onInquiryButtonClick}
            >
              {t("button.inquiry")}
            </Button>
          </>
        ),
      }}
      onCloseButtonClick={props.closeModal}
    />
  )
}

export default AnalysisInquiryModal
