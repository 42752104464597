import { useEffect, useRef } from "react"

export function useIntervalCallback(callBack: () => void) {
  const savedCallback = useRef(callBack)

  useEffect(() => {
    savedCallback.current = callBack
  })

  return savedCallback
}
