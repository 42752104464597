import React from "react"
import { useTranslation } from "react-i18next"

import { Layer } from "../layer-control-panel/LayerItems"
import styles from "./WeatheORainLegend.module.scss"

type Props = {
  activeLayers: Layer[]
}

export default function WeatheOLegendContainer(props: Props) {
  return (
    <div className={styles.legendsContainer}>
      {props.activeLayers.includes("GSMAP") && <GsmapLegend />}
      {props.activeLayers.includes("WEATHEO_RAIN") && <WeatheORainLegend />}
    </div>
  )
}

function WeatheORainLegend() {
  const { t } = useTranslation()

  return (
    <div className={styles.legendContainer}>
      <div className={styles.label}>
        <span className={styles.serviceName}>{t("service.weatheORain.title")}</span>
        <span className={styles.unit}>mm/h</span>
      </div>
      <div className={styles.legend}>
        <div className={styles.zero}>0</div>
        <div className={styles.one}>1</div>
        <div className={styles.five}>5</div>
        <div className={styles.ten}>10</div>
        <div className={styles.thirty}>30</div>
        <div className={styles.seventyToOneHundredTen}>
          <span>70</span>
          <span>110</span>
        </div>
      </div>
    </div>
  )
}

function GsmapLegend() {
  const { t } = useTranslation()

  return (
    <div className={styles.gsmapLegendContainer}>
      <div className={styles.label}>
        <span className={styles.serviceName}>{t("service.gSMaP.title")}</span>
        <span className={styles.unit}>mm/h</span>
      </div>
      <div className={styles.legend}>
        <span>0.1</span>
        <span>0.5</span>
        <span>1</span>
        <span>2</span>
        <span>3</span>
        <span>5</span>
        <span>10</span>
        <span>15</span>
        <span>20</span>
        <span>25</span>
      </div>
    </div>
  )
}
