import {
  initScene,
  MyLocationOutlined,
  Scene,
  COORDINATE_DAEJEON,
  generateOlMap,
  generateOsmLayer,
  generateUploadedTileLayer,
  generateUploadWmtsSceneLayer,
  generateVectorSourceFromWkb,
  LAYER_TILE_MY_IMAGES,
  LAYER_TILE_MY_IMAGES_Z_INDEX,
  LAYER_TILE_OSM,
  removeLayers,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { VerticalButtonGroup } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import { Map as OlMap, View } from "ol"
import { ScaleLine, defaults as defaultControls } from "ol/control"
import TileLayer from "ol/layer/Tile"
import { fromLonLat } from "ol/proj"
import TileSource from "ol/source/Tile"
import React, { SetStateAction, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, useOutletContext } from "react-router-dom"

import styles from "./MyImagesMap.module.scss"

export type myImagesMapOutletContextType = { activeImage: Scene; setActiveImage: React.Dispatch<SetStateAction<Scene>> }
export const useMyImagesMapOutletContext = () => {
  return useOutletContext<myImagesMapOutletContextType>()
}

type Props = {
  className?: string
}

function MyImagesMap(props: Props) {
  const [activeImage, setActiveImage] = useState<Scene>(initScene)
  const [loadingMap, setLoadingMap] = useState<boolean>(false)
  const [loadingTiles, setLoadingTiles] = useState<number>(0)
  const [loadedTiles, setLoadedTiles] = useState<number>(0)
  const { t } = useTranslation()

  const mapContainerRef = useRef<HTMLDivElement>(null)
  const mapRef = useRef<OlMap | null>(null)
  const scaleLineRef = useRef<HTMLDivElement>(null)
  const raster = generateOsmLayer()

  useEffect(() => {
    if (mapRef.current) return
    if (!mapContainerRef.current || !mapRef || !scaleLineRef.current) return

    const scaleLine = new ScaleLine({
      className: styles.olScaleLine,
      target: scaleLineRef.current,
    })

    mapRef.current = generateOlMap(
      [raster],
      mapContainerRef.current,
      new View({ center: COORDINATE_DAEJEON, zoom: 0 }),
      defaultControls({ rotate: false }).extend([scaleLine]),
    )
    mapRef.current?.on("loadstart", () => setLoadingMap(true))
    mapRef.current?.on("loadend", () => setLoadingMap(false))

    return () => {
      scaleLine.setMap(null)
      mapRef.current?.getAllLayers().map((_layer) => mapRef.current?.removeLayer(_layer))
      mapRef.current?.un("loadstart", () => setLoadingMap(true))
      mapRef.current?.un("loadend", () => setLoadingMap(false))
      mapRef.current?.setTarget(undefined)
      mapRef.current = null
    }
  }, [mapContainerRef.current])

  useEffect(() => {
    const addSceneLayer = async () => {
      if (!mapRef.current) return
      if (activeImage.id === "-1") return

      const _wkb = activeImage.boundary
      if (_wkb === "") return

      const _source = generateVectorSourceFromWkb(_wkb)
      const _targetExtent = _source.getExtent()
      try {
        let _tile: TileLayer<TileSource>

        if (activeImage.wmsUrl !== "") {
          _tile = await generateUploadWmtsSceneLayer(
            activeImage.wmsUrl,
            _targetExtent,
            LAYER_TILE_MY_IMAGES,
            LAYER_TILE_MY_IMAGES_Z_INDEX,
          )
        } else {
          _tile = generateUploadedTileLayer(
            activeImage.sceneUrl,
            _targetExtent,
            LAYER_TILE_MY_IMAGES,
            LAYER_TILE_MY_IMAGES_Z_INDEX,
          )
        }

        _tile.getSource()?.on("tileloadstart", () => setLoadingTiles((prev) => ++prev))
        _tile.getSource()?.on(["tileloadend", "tileloaderror"], () => setLoadedTiles((prev) => ++prev))
        mapRef.current?.addLayer(_tile)
        mapRef.current?.getView().fit(_targetExtent)
      } catch (e) {
        captureException(e)
      }
    }

    addSceneLayer()
    return () => {
      if (!mapRef.current) return
      setLoadingTiles(0)
      setLoadedTiles(0)
      removeLayers(mapRef.current, LAYER_TILE_MY_IMAGES, true)
    }
  }, [activeImage])

  const onMapButtonClick = () => {
    if (!mapRef.current) return
    mapRef.current?.getAllLayers().forEach((layer) => {
      if (layer.get("name") === LAYER_TILE_OSM) {
        const baseOsmLayer = layer
        baseOsmLayer.setVisible(!baseOsmLayer.getVisible())
      }
    })
  }

  const onMyLocationButtonClick = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { longitude, latitude } }) => {
          mapRef.current?.getView().setCenter(fromLonLat([longitude, latitude]))
          mapRef.current?.getView().setZoom(14)
        },
        (error) => captureException(error),
      )
    }
  }

  const outletContext = useMemo(
    () => ({
      activeImage,
      setActiveImage,
    }),
    [activeImage],
  )

  return (
    <div className={styles.baseMap}>
      <Outlet context={outletContext} />
      <div className={cn(styles.mapContainer, props.className)} ref={mapContainerRef}>
        <progress className={cn("tile-load-progress", loadingMap && "tile-loading")} max={"100"} value={"100"} />
        <VerticalButtonGroup
          groupClassName={"mapBtn"}
          size={"large"}
          options={[
            {
              label: t("button.myLocation") ?? "",
              icon: <MyLocationOutlined />,
              value: t("button.myLocation") ?? "",
              onClick: onMyLocationButtonClick,
            },
          ]}
        />
        <div className={styles.customScaleLine}>
          <span>©Open Street Map</span>
          <div ref={scaleLineRef} />
        </div>
      </div>
    </div>
  )
}
export default MyImagesMap
