export type InferenceResult = {
  aoi: string
  sceneUriList: (string | null)[]
  sceneWmsUrlList: (string | null)[]
  sceneExtentList: string[]
  summary: object
  resultDownloadUrl: string
  resultGisUrl: string
}

export type InferenceResultDto = Omit<
  InferenceResult,
  "results" | "sceneUriList" | "sceneWmsUrlList" | "sceneExtentList" | "summary" | "resultDownloadUrl" | "resultGisUrl"
> & {
  results: string | null
  sceneUriList: (string | null)[]
  sceneWmsUrlList: (string | null)[] | null
  sceneExtentList: string[] | null
  summary: object | null
  resultDownloadUrl?: string | null
  resultGisUrl: string | null
}

export const inferenceResultFromDto = (dto: InferenceResultDto): InferenceResult => {
  return {
    aoi: dto.aoi,
    sceneUriList: dto.sceneUriList ?? [],
    sceneExtentList: dto.sceneExtentList ?? [],
    summary: dto.summary ?? {},
    resultDownloadUrl: dto.resultDownloadUrl ?? "",
    resultGisUrl: dto.resultGisUrl ?? "",
    sceneWmsUrlList: dto.sceneWmsUrlList ?? [],
  }
}

export const isInitialInferenceResult = (inferenceResult: InferenceResult) => {
  return inferenceResult.resultGisUrl === ""
}
export const initInferenceResult = (): InferenceResult => {
  return {
    aoi: "",
    sceneUriList: [],
    sceneWmsUrlList: [],
    sceneExtentList: [],
    summary: {},
    resultDownloadUrl: "",
    resultGisUrl: "",
  }
}
