import {
  getToken,
  SERVER_URI,
  instance,
  LAYER_IR_Z_INDEX,
  LAYER_TILE_IR,
  LAYER_TILE_VI,
  LAYER_TILE_WEATHEO_RAIN,
  LAYER_VI_Z_INDEX,
  LAYER_WEATHEO_RAIN_Z_INDEX,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { ImageTile, Map as OlMap, Tile } from "ol"
import TileLayer from "ol/layer/Tile"
import { TileWMS, XYZ } from "ol/source"
import TileSource from "ol/source/Tile"
import { createXYZ } from "ol/tilegrid"
import TileState from "ol/TileState"

import type { Layer as WeatherLayer } from "../layer-control-panel/LayerItems"

export const generateIRLayer = (layer: string, opacity?: number) => {
  return new TileLayer({
    source: new TileWMS({
      url: `${SERVER_URI}/cog/georain`,
      params: { LAYERS: layer, VERSION: "1.0.0" },
      tileLoadFunction: getTileWithToken,
      tileGrid: createXYZ({ maxZoom: 5 }),
    }),
    zIndex: LAYER_IR_Z_INDEX,
    className: LAYER_TILE_IR,
    opacity,
  })
}

export const generateVILayer = (layer: string, opacity?: number) => {
  return new TileLayer({
    source: new TileWMS({
      url: `${SERVER_URI}/cog/georain`,
      params: { LAYERS: layer, VERSION: "1.0.0" },
      tileLoadFunction: getTileWithToken,
      tileGrid: createXYZ({ maxZoom: 5 }),
    }),
    zIndex: LAYER_VI_Z_INDEX,
    className: LAYER_TILE_VI,
    opacity,
  })
}

export const generateWeatheORainLayer = (layer: string, opacity?: number) => {
  return new TileLayer({
    source: new TileWMS({
      url: `${SERVER_URI}/cog/georain`,
      params: { LAYERS: layer, VERSION: "1.0.0" },
      tileLoadFunction: getTileWithToken,
      tileGrid: createXYZ({ maxZoom: 6 }),
    }),
    zIndex: LAYER_WEATHEO_RAIN_Z_INDEX,
    className: LAYER_TILE_WEATHEO_RAIN,
    opacity,
  })
}

export const getGsmapUrl = (
  date: Date,
  z: number | string = "{z}",
  x: number | string = "{x}",
  y: number | string = "{-y}",
) => {
  const minute = date.getUTCMinutes() >= 30 ? 30 : 0
  return `${gsMapBaseUrl}?prod=rain&year=${date.getUTCFullYear()}&month=${
    date.getUTCMonth() + 1
  }&day=${date.getUTCDate()}&hour=${date.getUTCHours()}&min=${minute}&z=${z}&x=${x}&y=${y}`
}

export const updateGSMap = (
  activeLayers: WeatherLayer[],
  map: OlMap,
  gsMapLayer: TileLayer<XYZ>,
  date: Date,
  gsmapEmptyMap: Map<string, boolean>,
) => {
  const url = getGsmapUrl(date, 0, 0, 0)

  if (activeLayers.includes("GSMAP") && gsmapEmptyMap.get(url)) {
    if (!map.getAllLayers().includes(gsMapLayer)) map.addLayer(gsMapLayer)
  } else {
    map.removeLayer(gsMapLayer)
  }

  gsMapLayer.getSource()?.setUrl(getGsmapUrl(date))
}

export const gsMapBaseUrl = "https://sharaku.eorc.jaxa.jp/cgi-bin/trmm/GSMaP_NOW/tilemap/tile_rain.py"

async function getTileWithToken(tile: Tile, url: string) {
  if (!(tile instanceof ImageTile)) return
  const image = tile.getImage()
  if (!(image instanceof HTMLImageElement)) return

  try {
    const res = await instance.get(url, { headers: { Authorization: `Bearer ${getToken()}` }, responseType: "arraybuffer" })
    const contentType = res.headers["content-type"]
    if (!contentType) throw new Error("There is no content type.")
    const data = Buffer.from(res.data, "binary").toString("base64")
    image.src = `data:${contentType.toLowerCase()};base64,${data}`
  } catch (e) {
    image.src = ""
    tile.setState(TileState.ERROR)
    captureException(e)
    return
  }
}

export const getTileLayerContext = (layer: TileLayer<TileSource>): Promise<CanvasRenderingContext2D> => {
  return new Promise((resolve, reject) => {
    const context = layer.getRenderer()?.context
    if (context) {
      resolve(context)
    } else {
      layer.once("postrender", (e) => {
        if (e.context instanceof CanvasRenderingContext2D) resolve(e.context)
        else reject("there's no context")
      })
    }
  })
}
