import {
  AiPackType,
  DrawRectangleOutlined,
  FileDownloadOutlined,
  FitScreenOutlined,
  getInferenceResult,
  getProjectsInferenceJobs,
  getScene,
  getToken,
  InferenceJobInfo,
  InferenceResult,
  initInferenceJobInfo,
  initInferenceResult,
  initProject,
  initProvider,
  initScene,
  isError,
  isInitialInferenceJobInfo,
  isInitialInferenceResult,
  isInitialProject,
  MyLocationOutlined,
  PATH_ANALYSIS,
  PATH_CREATE_PROJECT,
  PATH_PROJECT_INFO,
  Project,
  ProjectType,
  Provider,
  ProviderType,
  Scene,
  SourceType,
  useInterval,
  usePageVisibility,
  getAiPack,
  AREA_AOI_LIMIT,
  CONTROL_SWIPE,
  COORDINATE_DAEJEON,
  generateOlMap,
  generateOsmLayer,
  generateTileLayer,
  generateUploadedTileLayer,
  generateUploadWmtsSceneLayer,
  generateVectorLayerForAoi,
  generateVectorLayerForLand,
  generateVectorLayerForObjectDetection,
  generateVectorLayerForSegmentation,
  generateVectorSource,
  generateVectorSourceFromGeoJson,
  generateVectorSourceFromWkb,
  generateWmtsSceneLayer,
  generateWmtsSource,
  generateXyzTileLayer,
  getLayers,
  LAYER_TILE_ANALYSIS,
  LAYER_TILE_ANALYSIS_Z_INDEX,
  LAYER_TILE_CREATE_PROJECT_MY_IMAGES,
  LAYER_TILE_CREATE_PROJECT_MY_IMAGES_Z_INDEX,
  LAYER_TILE_CREATE_PROJECT_PROVIDER,
  LAYER_TILE_CREATE_PROJECT_PROVIDER_Z_INDEX,
  LAYER_VECTOR_ANALYSIS,
  LAYER_VECTOR_AOI,
  LEGEND_COLOR_LAND_COVER_AGRICULTURE_LAND,
  LEGEND_COLOR_LAND_COVER_BARELAND,
  LEGEND_COLOR_LAND_COVER_BUILDING,
  LEGEND_COLOR_LAND_COVER_DEVELOPED_SPACE,
  LEGEND_COLOR_LAND_COVER_RANGELAND,
  LEGEND_COLOR_LAND_COVER_ROAD,
  LEGEND_COLOR_LAND_COVER_TREE,
  LEGEND_COLOR_LAND_COVER_WATER,
  removeLayers,
  setAoiStyle,
  VECTOR_COLOR_OBJECT_DETECTION,
  getProviderFromUrl,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { HorizontalButtonGroup, IconButton, Switch, Toast, VerticalButtonGroup } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import { Map as OlMap, View } from "ol"
import Swipe from "ol-ext/control/Swipe"
import { defaults as defaultControls, ScaleLine } from "ol/control"
import { Extent } from "ol/extent"
import { Polygon } from "ol/geom"
import { Draw } from "ol/interaction"
import { createBox } from "ol/interaction/Draw"
import TileLayer from "ol/layer/Tile"
import VectorLayer from "ol/layer/Vector"
import { fromLonLat } from "ol/proj"
import TileSource from "ol/source/Tile"
import VectorSource from "ol/source/Vector"
import React, { SetStateAction, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, useLocation, useOutletContext } from "react-router-dom"

import { useServiceLayoutOutletContextType } from "../service-layout/ServiceLayout"
import { SettingsMenu } from "../settings/Settings"
import styles from "./AnalysisMap.module.scss"
import AnalysisDetail from "./result/AnalysisDetail"
import AnalysisImages from "./result/AnalysisImages"
import ControlCard from "./result/ControlCard"
import Legend, { LegendContent } from "./result/Legend"
import ResultExportPopover from "./result/ResultExportPopover"
import SearchMap from "./SearchMap"

export type analysisMapOutletContextType = {
  isSettingsVisible: boolean
  setIsSettingsVisible: React.Dispatch<SetStateAction<boolean>>
  settingsMenu: SettingsMenu
  setSettingsMenu: React.Dispatch<SetStateAction<SettingsMenu>>

  selectedProject: Project
  setSelectedProject: React.Dispatch<SetStateAction<Project>>
  selectedLocalImage: Scene
  setSelectedLocalImage: React.Dispatch<SetStateAction<Scene>>
  aoiArea: number
  setAoiArea: React.Dispatch<SetStateAction<number>>
  aiPack: AiPackType
  setAiPack: React.Dispatch<SetStateAction<AiPackType>>
  aoiPolygon: Polygon
  setAoiPolygon: React.Dispatch<SetStateAction<Polygon | null>>
  project: ProjectType
  setProject: React.Dispatch<SetStateAction<ProjectType>>
  source: SourceTabType
  setSource: React.Dispatch<SetStateAction<SourceTabType>>
  provider: ProviderType | null
  setProvider: React.Dispatch<SetStateAction<ProviderType | null>>
  providerUrl: Provider
  setProviderUrl: React.Dispatch<SetStateAction<Provider>>
  waybackLayers: WaybackLayerType[]
  waybackLayer: WaybackLayerType
  setWaybackLayer: React.Dispatch<SetStateAction<WaybackLayerType>>
  isProjectEditing: boolean
  setIsProjectEditing: React.Dispatch<SetStateAction<boolean>>
  view: View
}

export const useAnalysisMapOutletContext = () => {
  return useOutletContext<analysisMapOutletContextType>()
}

type SourceTabType = Exclude<SourceType, "CLOUD">
type WaybackLayerType = {
  title: string
  identifier: string
}
export const initWaybackLayer = (): WaybackLayerType => {
  return { title: "", identifier: "" }
}

type Props = {
  className?: string
}

function AnalysisMap(props: Props) {
  const { isSettingsVisible, setIsSettingsVisible, settingsMenu, setSettingsMenu } = useServiceLayoutOutletContextType()
  /* Used in Analysis Page */
  const [selectedProject, setSelectedProject] = useState<Project>(initProject)
  const [selectedProjectJob, setSelectedProjectJob] = useState<InferenceJobInfo>(initInferenceJobInfo)
  const [inferenceResult, setInferenceResult] = useState<InferenceResult>(initInferenceResult)
  const [resultExtent, setResultExtent] = useState<Extent | null>(null)
  const [isResultExportPopoverVisible, setIsResultExportPopoverVisible] = useState<boolean>(false)
  const [isShowResultActive, setIsShowResultActive] = useState<boolean>(true)
  /* Used in ProjectInfo Page */
  const [isProjectEditing, setIsProjectEditing] = useState<boolean>(false)
  /* Used in CreateProject Page */
  const [project, setProject] = useState<ProjectType>("REALTIME")
  const [providerUrl, setProviderUrl] = useState<Provider>(initProvider)
  const [source, setSource] = useState<SourceTabType>("PROVIDERS")
  const [provider, setProvider] = useState<ProviderType | null>(null)
  const [waybackLayers, setWaybackLayers] = useState<WaybackLayerType[]>([])
  const [waybackLayer, setWaybackLayer] = useState<WaybackLayerType>(initWaybackLayer)
  const [selectedLocalImage, setSelectedLocalImage] = useState<Scene>(initScene)
  const [aiPack, setAiPack] = useState<AiPackType>("")
  const [aoiArea, setAoiArea] = useState<number>(0)
  const [aoiPolygon, setAoiPolygon] = useState<Polygon | null>(null)
  /* Use in CreateProject - Search Page */
  const [center, setCenter] = useState<number[]>([])

  const [loadingMap, setLoadingMap] = useState<boolean>(false)
  const [loadingTiles, setLoadingTiles] = useState<number>(0)
  const [loadedTiles, setLoadedTiles] = useState<number>(0)
  const location = useLocation()
  const { t } = useTranslation()
  const isPageVisible = usePageVisibility()

  const mapContainerRef = useRef<HTMLDivElement>(null)
  const mapRef = useRef<OlMap | null>(null)
  const scaleLineRef = useRef<HTMLDivElement>(null)
  const raster = generateOsmLayer()

  const isImagesPanelVisible = () => {
    return (
      location.pathname === PATH_PROJECT_INFO && !isInitialProject(selectedProject) && selectedProject.type === "REALTIME"
    )
  }

  const isSingleProjectFetching =
    location.pathname === PATH_PROJECT_INFO &&
    !isInitialProject(selectedProject) &&
    selectedProject.type === "SINGLE" &&
    !isInitialInferenceJobInfo(selectedProjectJob) &&
    selectedProjectJob.status !== "COMPLETED" &&
    selectedProjectJob.status !== "ERROR"

  useInterval(
    () => {
      void setProjectsInferenceJobAsync()
    },
    isPageVisible && isSingleProjectFetching ? 5000 : null,
    true,
  )
  const setProjectsInferenceJobAsync = async () => {
    if (isInitialProject(selectedProject)) return

    try {
      const _job = await getProjectsInferenceJobs(selectedProject.id)
      if (!isError(_job) && _job.totalCount === 1) setSelectedProjectJob(_job.inferenceJobInfoList[0])
    } catch (e) {
      captureException(e)
    }
  }

  useEffect(() => {
    if (mapRef.current) return
    if (!mapContainerRef.current || !mapRef || !scaleLineRef.current) return

    const scaleLine = new ScaleLine({
      className: styles.olScaleLine,
      target: scaleLineRef.current,
    })

    mapRef.current = generateOlMap(
      [raster],
      mapContainerRef.current,
      new View({ center: COORDINATE_DAEJEON, zoom: 0 }),
      defaultControls({ rotate: false }).extend([scaleLine]),
    )
    mapRef.current?.on("loadstart", () => setLoadingMap(true))
    mapRef.current?.on("loadend", () => setLoadingMap(false))

    return () => {
      scaleLine.setMap(null)
      mapRef.current?.getAllLayers().map((_layer) => mapRef.current?.removeLayer(_layer))
      mapRef.current?.un("loadstart", () => setLoadingMap(true))
      mapRef.current?.un("loadend", () => setLoadingMap(false))
      mapRef.current?.setTarget(undefined)
      mapRef.current = null
    }
  }, [mapContainerRef.current])

  useEffect(() => {
    if (!mapRef.current) return
    removeLayers(mapRef.current, LAYER_TILE_CREATE_PROJECT_PROVIDER, true)

    if (location.pathname === PATH_ANALYSIS) {
      setSelectedProjectJob(initInferenceJobInfo)
    } else if (location.pathname === PATH_PROJECT_INFO) {
      setSelectedProjectJob(initInferenceJobInfo)

      // NOTE: REALTIME인 경우 AnalysisImages 컴포넌트 사용
      if (selectedProject.type === "SINGLE") void setProjectsInferenceJobAsync()
    } else if (location.pathname === PATH_CREATE_PROJECT) {
      if (!project || !source) return
      if (project === "REALTIME") {
        // Only Bucket
        setProvider(null)
        setSelectedLocalImage(initScene)
      } else if (project === "SINGLE") {
        // Only Provider, MyImages
        if (source === "PROVIDERS") {
          setSelectedLocalImage(initScene)
          if (provider) setWmtsTileLayer()
          else setProvider(null)
        } else {
          setProvider(null)
        }
      }
    }

    return () => {
      if (!mapRef.current) return
      removeLayers(mapRef.current, LAYER_TILE_CREATE_PROJECT_PROVIDER, true)
      if (location.pathname === PATH_PROJECT_INFO) setIsProjectEditing(false)
      setLoadingTiles(0)
      setLoadedTiles(0)
    }
  }, [location.pathname, isProjectEditing, selectedProject, project, source, provider])

  useEffect(() => {
    if (location.pathname === PATH_PROJECT_INFO) {
      if (isInitialInferenceJobInfo(selectedProjectJob) || selectedProjectJob.status !== "COMPLETED") {
        setInferenceResult(initInferenceResult)
        return
      }
      const setInferenceResultAsync = async () => {
        try {
          const _result = await getInferenceResult(selectedProjectJob.id)
          if (!isError(_result)) setInferenceResult(_result)
        } catch (e) {
          setInferenceResult(initInferenceResult)
          captureException(e)
        }
      }
      void setInferenceResultAsync()
    }

    return () => {
      setInferenceResult(initInferenceResult)
    }
  }, [location.pathname, selectedProjectJob])

  useEffect(() => {
    if (!mapRef.current) return
    if (location.pathname === PATH_PROJECT_INFO) {
      const addSceneLayers = async () => {
        if (inferenceResult.sceneUriList.length === 0 || inferenceResult.aoi === "") return

        const _aoiSource = generateVectorSourceFromWkb(inferenceResult.aoi)
        const _aoiVector = generateVectorLayerForAoi(_aoiSource)
        const _aoiExtent = _aoiSource.getExtent()
        if (!_aoiExtent) {
          captureException("AOI Extent Error", (scope) => {
            scope.setContext("AOI", { aoi: inferenceResult.aoi })
            return scope
          })
          return
        }

        if (inferenceResult.sceneUriList.length === 2 && inferenceResult.sceneExtentList.length === 2) {
          // SE AI Pack
          const _sceneTileLayers: TileLayer<TileSource>[] = []

          for (let i = 0; i < inferenceResult.sceneUriList.length; i++) {
            const sceneUri = inferenceResult.sceneUriList[i]
            const wmsUrl = inferenceResult.sceneWmsUrlList[i]

            // @TODO: refactor to reference of packages/admin/src/routes/Viewer.tsx:116
            if (wmsUrl) {
              const isUploadTile = /ovision/.test(wmsUrl)

              if (isUploadTile) {
                _sceneTileLayers.push(
                  await generateUploadWmtsSceneLayer(
                    wmsUrl,
                    _aoiExtent,
                    LAYER_TILE_ANALYSIS,
                    LAYER_TILE_ANALYSIS_Z_INDEX + i,
                  ),
                )
              } else {
                _sceneTileLayers.push(
                  await generateWmtsSceneLayer(
                    getProviderFromUrl(wmsUrl),
                    wmsUrl,
                    _aoiExtent,
                    LAYER_TILE_ANALYSIS,
                    LAYER_TILE_ANALYSIS_Z_INDEX + i,
                  ),
                )
              }
            } else if (sceneUri) {
              const isUploadTile = /ovision/.test(sceneUri)
              if (isUploadTile) {
                _sceneTileLayers.push(
                  generateUploadedTileLayer(sceneUri, _aoiExtent, LAYER_TILE_ANALYSIS, LAYER_TILE_ANALYSIS_Z_INDEX + i),
                )
              } else {
                _sceneTileLayers.push(
                  generateXyzTileLayer(sceneUri, 0, _aoiExtent, LAYER_TILE_ANALYSIS, LAYER_TILE_ANALYSIS_Z_INDEX + i),
                )
              }
            }
          }

          _sceneTileLayers.map((_layer) => {
            _layer.getSource()?.on("tileloadstart", () => setLoadingTiles((prev) => ++prev))
            _layer.getSource()?.on(["tileloadend", "tileloaderror"], () => setLoadedTiles((prev) => ++prev))
            mapRef.current?.addLayer(_layer)
          })

          const olSwipe = new Swipe()
          olSwipe.set("name", CONTROL_SWIPE)
          mapRef.current?.addControl(olSwipe)
          olSwipe.addLayer(_sceneTileLayers[0], false)
          olSwipe.addLayer(_sceneTileLayers[1], true)
        } else if (!isInitialInferenceResult(inferenceResult)) {
          if (isInitialProject(selectedProject)) return
          const aiPack = selectedProject.aiPack.type
          const isLandCover = aiPack === "LandCoverSegmentation"
          const isInfraAiPack = aiPack.includes("Segmentation")
          const _geoJsonSource = generateVectorSourceFromGeoJson(inferenceResult.resultGisUrl)

          let _geoJsonVector
          if (isLandCover) _geoJsonVector = generateVectorLayerForLand(_geoJsonSource)
          else if (isInfraAiPack) _geoJsonVector = generateVectorLayerForSegmentation(_geoJsonSource)
          else _geoJsonVector = generateVectorLayerForObjectDetection(_geoJsonSource)

          let _sceneTileLayer: TileLayer<TileSource> | null = null
          // @TODO: refactor to reference of packages/admin/src/routes/Viewer.tsx:116
          if (inferenceResult.sceneWmsUrlList[0]) {
            const isUploadTile = /ovision/.test(inferenceResult.sceneWmsUrlList[0])

            if (isUploadTile) {
              _sceneTileLayer = await generateUploadWmtsSceneLayer(
                inferenceResult.sceneWmsUrlList[0],
                _aoiExtent,
                LAYER_TILE_ANALYSIS,
                LAYER_TILE_ANALYSIS_Z_INDEX,
              )
            } else {
              _sceneTileLayer = await generateWmtsSceneLayer(
                getProviderFromUrl(inferenceResult.sceneWmsUrlList[0]),
                inferenceResult.sceneWmsUrlList[0],
                _aoiExtent,
                LAYER_TILE_ANALYSIS,
                LAYER_TILE_ANALYSIS_Z_INDEX,
              )
            }
          } else if (inferenceResult.sceneUriList[0]) {
            const isUploadTile = /ovision/.test(inferenceResult.sceneUriList[0])

            if (isUploadTile) {
              _sceneTileLayer = generateUploadedTileLayer(
                inferenceResult.sceneUriList[0],
                _aoiExtent,
                LAYER_TILE_ANALYSIS,
                LAYER_TILE_ANALYSIS_Z_INDEX,
              )
            } else {
              _sceneTileLayer = generateXyzTileLayer(
                inferenceResult.sceneUriList[0],
                0,
                _aoiExtent,
                LAYER_TILE_ANALYSIS,
                LAYER_TILE_ANALYSIS_Z_INDEX,
              )
            }
          }

          if (_sceneTileLayer !== null) {
            _sceneTileLayer.getSource()?.on("tileloadstart", () => setLoadingTiles((prev) => ++prev))
            _sceneTileLayer.getSource()?.on(["tileloadend", "tileloaderror"], () => setLoadedTiles((prev) => ++prev))
            mapRef.current?.addLayer(_sceneTileLayer)
          }
          if (_geoJsonVector) mapRef.current?.addLayer(_geoJsonVector)
        }
        mapRef.current?.addLayer(_aoiVector)
        mapRef.current?.getView().fit(_aoiExtent)
        setResultExtent(_aoiExtent)
      }

      addSceneLayers()
    } else if (location.pathname === PATH_CREATE_PROJECT) {
      if (!mapRef.current) return
      removeLayers(mapRef.current, LAYER_VECTOR_AOI)
      removeLayers(mapRef.current, LAYER_VECTOR_ANALYSIS)
      removeLayers(mapRef.current, LAYER_TILE_ANALYSIS, true)
    }

    return () => {
      if (!mapRef.current) return
      mapRef.current?.getControls().forEach((ctrl) => {
        if (ctrl.get("name") === CONTROL_SWIPE) mapRef.current?.removeControl(ctrl)
      })
      removeLayers(mapRef.current, LAYER_VECTOR_AOI)
      removeLayers(mapRef.current, LAYER_VECTOR_ANALYSIS)
      removeLayers(mapRef.current, LAYER_TILE_ANALYSIS, true)
      removeLayers(mapRef.current, LAYER_TILE_CREATE_PROJECT_PROVIDER, true)
      setLoadingTiles(0)
      setLoadedTiles(0)
      setIsShowResultActive(true)
    }
  }, [location.pathname, inferenceResult])

  useEffect(() => {
    if (!mapRef.current) return
    if (location.pathname === PATH_CREATE_PROJECT) {
      if (source === "PROVIDERS" && provider === "WAYBACK" && waybackLayer.title !== "" && waybackLayer.identifier !== "")
        setWmtsTileLayer()
    }

    return () => {
      if (!mapRef.current) return
      removeLayers(mapRef.current, LAYER_TILE_CREATE_PROJECT_PROVIDER, true)
      setLoadingTiles(0)
      setLoadedTiles(0)
    }
  }, [location.pathname, waybackLayer])

  useEffect(() => {
    const addSceneLayer = async () => {
      if (!mapRef.current) return
      removeLayers(mapRef.current, LAYER_TILE_CREATE_PROJECT_MY_IMAGES, true)

      if (location.pathname === PATH_CREATE_PROJECT) {
        if (project !== "SINGLE" || selectedLocalImage.id === "-1") return

        const _wkb = selectedLocalImage.boundary
        if (_wkb === "") return

        const _source = generateVectorSourceFromWkb(_wkb)
        const _targetExtent = _source.getExtent()
        let _tile

        try {
          if (selectedLocalImage.wmsUrl !== "") {
            _tile = await generateUploadWmtsSceneLayer(
              selectedLocalImage.wmsUrl,
              _targetExtent,
              LAYER_TILE_CREATE_PROJECT_MY_IMAGES,
              LAYER_TILE_CREATE_PROJECT_MY_IMAGES_Z_INDEX,
            )
          } else {
            _tile = generateUploadedTileLayer(
              selectedLocalImage.sceneUrl,
              _targetExtent,
              LAYER_TILE_CREATE_PROJECT_MY_IMAGES,
              LAYER_TILE_CREATE_PROJECT_MY_IMAGES_Z_INDEX,
            )
          }

          _tile.getSource()?.on("tileloadstart", () => setLoadingTiles((prev) => ++prev))
          _tile.getSource()?.on(["tileloadend", "tileloaderror"], () => setLoadedTiles((prev) => ++prev))
          mapRef.current?.addLayer(_tile)
          mapRef.current?.getView().fit(_targetExtent)
        } catch (e) {
          captureException(e)
        }
      }
    }

    addSceneLayer()
    return () => {
      if (!mapRef.current) return
      setAoiArea(0)
      setAoiPolygon(null)
      removeLayers(mapRef.current, LAYER_VECTOR_AOI)
      removeLayers(mapRef.current, LAYER_TILE_CREATE_PROJECT_MY_IMAGES, true)
      setLoadingTiles(0)
      setLoadedTiles(0)
    }
  }, [selectedLocalImage])

  useEffect(() => {
    if (aiPack === "" || source !== "PROVIDERS") return
    if (mapRef.current && !aoiPolygon) {
      removeLayers(mapRef.current, LAYER_VECTOR_AOI)
      return
    }

    if (location.pathname === PATH_CREATE_PROJECT) {
      const limit = (parseInt(t("aiPack.aoi.limit")) || AREA_AOI_LIMIT) / 1e6
      if (Math.ceil(aoiArea) > limit)
        Toast({ message: t("toast.createProject.aoiLimit.error", { limit: limit }), type: "error" })
    }
  }, [aoiPolygon, aoiArea, aiPack, t])

  useEffect(() => {
    if (!mapRef.current || center.length === 0) return
    mapRef.current.getView().setCenter(fromLonLat(center))
    mapRef.current.getView().setZoom(14)
  }, [center])

  const setWmtsTileLayer = () => {
    if (providerUrl.mapbox === "" && providerUrl.wayback === "") {
      const setProviderUrlAsync = async () => {
        try {
          const _providerUrl = await getScene()
          if (!isError(_providerUrl)) {
            setProviderUrl(_providerUrl)
            addWmtsTileLayer(_providerUrl)
          }
        } catch (e) {
          captureException(e)
        }
      }
      void setProviderUrlAsync()
    } else {
      addWmtsTileLayer(providerUrl)
    }
  }
  const addWmtsTileLayer = (url: Provider) => {
    if (!mapRef.current || !provider) return
    let _url
    let _urlCheck
    let _config
    let _label = ""
    if (provider === "MAPBOX") {
      _url = url.mapbox
      _urlCheck = url.mapbox
      _config = { layer: "clb5za3o3000d15lb7bt7nayt", matrixSet: "GoogleMapsCompatible", crossOrigin: "anonymous" }
      _label = t("analysis.source.mapbox.label")
    } else if (provider === "WAYBACK") {
      _url = url.wayback + "1.0.0/WMTSCapabilities.xml"
      _urlCheck = url.wayback
      _config = { layer: waybackLayer?.identifier || "WAYBACK", matrixSet: "default028mm", crossOrigin: "anonymous" }
      _label = t("analysis.source.wayback.label")
    }
    if (!_urlCheck || !_url || !_config) {
      Toast({ message: t("toast.createProject.providerFetched.error", { provider: _label }), type: "error" })
      return
    }

    const _wmts = generateWmtsSource(provider, _url, _config)
    if (_wmts) {
      _wmts.then((ret) => {
        if (ret) {
          const { source, layers } = ret
          const _tile = generateTileLayer(
            source,
            0,
            undefined,
            LAYER_TILE_CREATE_PROJECT_PROVIDER,
            LAYER_TILE_CREATE_PROJECT_PROVIDER_Z_INDEX,
          )
          _tile.getSource()?.on("tileloadstart", () => setLoadingTiles((prev) => ++prev))
          _tile.getSource()?.on(["tileloadend", "tileloaderror"], () => setLoadedTiles((prev) => ++prev))
          mapRef.current?.addLayer(_tile)
          if (provider === "WAYBACK") {
            const _layers = layers.map((layer): WaybackLayerType => {
              return {
                title: "Title" in layer ? layer["Title"]!.toString().split("Wayback ")[1]?.slice(0, -1) : "",
                identifier: "Identifier" in layer ? layer["Identifier"]!.toString() : "",
              }
            })
            const filtered = _layers.filter((_layer) => _layer.title !== "" && _layer.identifier !== "")
            setWaybackLayers(filtered)
          }
        } else {
          Toast({ message: t("toast.createProject.providerGenerated.error", { provider: _label }), type: "error" })
        }
      })
    } else {
      Toast({ message: t("toast.createProject.providerFetched.error", { provider: _label }), type: "error" })
    }
  }

  const drawAoi = () => {
    if (!mapRef.current) return
    const _source = generateVectorSource()

    const draw = new Draw({
      source: _source,
      type: "Circle",
      geometryFunction: createBox(),
      style: setAoiStyle(4, true),
      freehand: true,
    })
    const keyboardEventListener = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        draw.abortDrawing()
        mapRef.current?.removeInteraction(draw)
        document.removeEventListener("keydown", keyboardEventListener)
      }
    }
    document.addEventListener("keydown", keyboardEventListener)

    draw.on("drawend", (evt) => {
      mapRef.current?.removeInteraction(draw)
      const _geometry = evt.feature.getGeometry()
      if (_geometry) {
        setAoiPolygon(_geometry as Polygon)
        const _vector = generateVectorLayerForAoi(_source)
        mapRef.current?.addLayer(_vector)
      }
    })
    mapRef.current.addInteraction(draw)
  }

  const onAddAoiButtonClick = () => {
    if (!mapRef.current) return
    if (findDrawInteraction(mapRef.current)) return
    if (source === "PROVIDERS") setAoiArea(0)
    setAoiPolygon(null)
    removeLayers(mapRef.current, LAYER_VECTOR_AOI)
    drawAoi()
  }

  const onFitScreenButtonClick = () => {
    if (resultExtent) mapRef.current?.getView().fit(resultExtent)
    // TODO: fit 실패 처리
  }

  const onShowResultSlideClick = () => {
    if (!mapRef.current) return
    getLayers(mapRef.current, LAYER_VECTOR_ANALYSIS).map((_layer) => {
      const _visible = _layer.getVisible()
      setIsShowResultActive(!_visible)
      _layer.setVisible(!_visible)
    })
  }
  const getObjectDetectionLegendContents = (): LegendContent[] => {
    if (!mapRef.current) return []
    const _layers = getLayers(mapRef.current, LAYER_VECTOR_ANALYSIS)
    const _count = _layers.map((_layer) => (_layer as VectorLayer<VectorSource>).getSource()?.getFeatures().length ?? 0)
    if (_layers.length === 1) {
      return [
        {
          text: getAiPack(selectedProject.aiPack.type).label,
          count: _count.at(0),
          style: { background: VECTOR_COLOR_OBJECT_DETECTION },
        },
      ]
    } else {
      return []
    }
  }

  const onMyLocationButtonClick = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { longitude, latitude } }) => {
          setCenter([longitude, latitude])
        },
        (error) => captureException(error),
      )
    }
  }

  const view = mapRef.current?.getView()
  const outletContext = useMemo(
    () => ({
      isSettingsVisible,
      setIsSettingsVisible,
      settingsMenu,
      setSettingsMenu,
      selectedProject,
      setSelectedProject,
      selectedLocalImage,
      setSelectedLocalImage,
      aiPack,
      setAiPack,
      aoiArea,
      setAoiArea,
      aoiPolygon,
      setAoiPolygon,
      project,
      setProject,
      source,
      setSource,
      provider,
      setProvider,
      providerUrl,
      setProviderUrl,
      waybackLayers,
      waybackLayer,
      setWaybackLayer,
      isProjectEditing,
      setIsProjectEditing,
      view,
    }),
    [
      isSettingsVisible,
      setIsSettingsVisible,
      settingsMenu,
      setSettingsMenu,
      selectedProject,
      selectedLocalImage,
      aiPack,
      aoiArea,
      aoiPolygon,
      project,
      source,
      provider,
      providerUrl,
      waybackLayers,
      waybackLayer,
      isProjectEditing,
      view,
    ],
  )

  return (
    <div className={styles.analysisMapContainer}>
      <Outlet context={outletContext} />
      <div className={styles.analysisMap}>
        {location.pathname === PATH_PROJECT_INFO && (
          <header className={styles.header}>
            <div className={styles.left}>
              <span className={styles.title}>{""}</span>
            </div>
            <div className={styles.right}>
              <IconButton
                wrapperClassName={styles.toolBtn}
                type={"square"}
                disabled={isInitialInferenceJobInfo(selectedProjectJob) || selectedProjectJob.status !== "COMPLETED"}
                icon={<FitScreenOutlined />}
                onClick={onFitScreenButtonClick}
              />
              <ResultExportPopover
                className={cn(styles.resultExportPopover, isImagesPanelVisible() && styles.images)}
                downloadUrl={inferenceResult.resultDownloadUrl}
                exportUrl={inferenceResult.resultGisUrl + `&access_token=${getToken()}`}
                isVisible={isResultExportPopoverVisible}
                service={inferenceResult.sceneUriList.length === 2 ? "IMAGE_IMPROVEMENT" : undefined}
                setIsVisible={setIsResultExportPopoverVisible}
              >
                <IconButton
                  wrapperClassName={styles.toolBtn}
                  type={"square"}
                  disabled={isInitialInferenceJobInfo(selectedProjectJob) || selectedProjectJob.status !== "COMPLETED"}
                  icon={<FileDownloadOutlined />}
                  onClick={() => setIsResultExportPopoverVisible(!isResultExportPopoverVisible)}
                />
              </ResultExportPopover>
            </div>
          </header>
        )}
        <div
          className={cn(
            styles.mapContainer,
            location.pathname === PATH_PROJECT_INFO && styles.projectInfo,
            isImagesPanelVisible() && styles.images,
            props.className,
          )}
          ref={mapContainerRef}
        >
          {location.pathname === PATH_PROJECT_INFO && !isInitialInferenceJobInfo(selectedProjectJob) && (
            <>
              <AnalysisDetail data={selectedProjectJob} projectType={selectedProject.type} />
              {selectedProject.aiPack.type === "LandCoverSegmentation" && (
                <Legend
                  contents={[
                    { text: t("legend.bareland.label"), style: { background: LEGEND_COLOR_LAND_COVER_BARELAND } },
                    { text: t("legend.rangeland.label"), style: { background: LEGEND_COLOR_LAND_COVER_RANGELAND } },
                    {
                      text: t("legend.developedSpace.label"),
                      style: { background: LEGEND_COLOR_LAND_COVER_DEVELOPED_SPACE },
                    },
                    { text: t("legend.road.label"), style: { background: LEGEND_COLOR_LAND_COVER_ROAD } },
                    { text: t("legend.tree.label"), style: { background: LEGEND_COLOR_LAND_COVER_TREE } },
                    { text: t("legend.water.label"), style: { background: LEGEND_COLOR_LAND_COVER_WATER } },
                    {
                      text: t("legend.agricultureLand.label"),
                      style: { background: LEGEND_COLOR_LAND_COVER_AGRICULTURE_LAND },
                    },
                    { text: t("legend.building.label"), style: { background: LEGEND_COLOR_LAND_COVER_BUILDING } },
                  ]}
                />
              )}
              {selectedProjectJob.status === "COMPLETED" && (
                <>
                  {selectedProject.aiPack.type.endsWith("Detection") && ( // @TODO: handle service field
                    <Legend contents={getObjectDetectionLegendContents()} />
                  )}
                  {selectedProject.aiPack.type !== "SuperEnhancement" && (
                    <ControlCard className={cn(styles.controlCard, styles.left)}>
                      <Switch active={isShowResultActive} onClick={onShowResultSlideClick}>
                        {t("controlCard.showResults.label")}
                      </Switch>
                    </ControlCard>
                  )}
                </>
              )}
            </>
          )}
          {location.pathname === PATH_CREATE_PROJECT && (
            <>
              <SearchMap setCenter={setCenter} />
              <div className={styles.center}>
                {aiPack !== "SuperEnhancement" && (
                  <HorizontalButtonGroup
                    groupClassName={styles.drawAoiBtn}
                    size={"large"}
                    options={[
                      {
                        label: t("button.drawAAoi"),
                        icon: <DrawRectangleOutlined />,
                        value: t("button.drawAAoi"),
                        onClick: onAddAoiButtonClick,
                      },
                    ]}
                  />
                )}
              </div>
              <VerticalButtonGroup
                groupClassName={"mapBtn"}
                size={"large"}
                options={[
                  {
                    label: t("button.myLocation") ?? "",
                    icon: <MyLocationOutlined />,
                    value: t("button.myLocation") ?? "",
                    onClick: onMyLocationButtonClick,
                  },
                ]}
              />
            </>
          )}
          <progress className={cn("tile-load-progress", loadingMap && "tile-loading")} max={"100"} value={"100"} />
          <div className={styles.customScaleLine}>
            <span>©Open Street Map</span>
            <div ref={scaleLineRef} />
          </div>
        </div>
      </div>
      {isImagesPanelVisible() && (
        <AnalysisImages
          selectedProject={selectedProject}
          selectedProjectJob={selectedProjectJob}
          onAnalysisJobClick={(job) => setSelectedProjectJob(job)}
        />
      )}
    </div>
  )
}
export default AnalysisMap

function findDrawInteraction(map: OlMap) {
  return map
    .getInteractions()
    .getArray()
    .find((value) => value instanceof Draw)
}
