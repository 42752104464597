import { getWeatheORainCapabilities } from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { WMSCapabilities } from "ol/format"
import React, { useEffect, useRef, useState } from "react"

import { WMSCapabilitiesJSON } from "../../common/wmsCapabilitiesType"
import LayerControl from "./layer-control-panel/LayerControlPanel"
import type { Layer, LayerOpacityMap } from "./layer-control-panel/LayerItems"
import MapThemeButton, { MapThemes } from "./map-theme-button/MapThemeButton"
import TimeLine, { getDateList, INITIAL_VALUE, MAX_VALUE } from "./timeline/TimeLine"
import WeatheOLegendContainer from "./weatheo-rain-legend/WeatheORainLegend"
import WeatheOLayerGroup from "./weather-map/WeatheOLayerGroup"
import { getGsmapUrl, gsMapBaseUrl } from "./weather-map/weatherMapUtil"
import WeatherMap from "./WeatherMap"

function Weather() {
  const [mapTheme, setMapTheme] = useState<MapThemes>("DarkOSM")
  const [activeLayers, setActiveLayers] = useState<Layer[]>(["WEATHEO_RAIN"])
  const [layerOpacities, setLayerOpacities] = useState<LayerOpacityMap>(
    new Map([
      ["WEATHEO_RAIN", 1],
      ["VISIBLE_IMAGE", 0.5],
      ["INFRARED_IMAGE", 0.5],
      ["GSMAP", 1],
    ]),
  )
  const dateList = useRef(getDateList())
  const [value, setValue] = useState(INITIAL_VALUE)
  const [date, setDate] = useState<Date>(dateList.current[INITIAL_VALUE])
  const [isPlay, setIsPlay] = useState(false)
  const [capabilities, setCapabilities] = useState<WMSCapabilitiesJSON>()
  const [gsmapEmptyMap, setGsmapEmptyMap] = useState<Map<string, boolean>>(new Map())

  const handlePlayChange = (isPlay: boolean) => {
    setIsPlay(isPlay)
    if (isPlay && activeLayers.length === 0) setActiveLayers(activeLayers.concat("WEATHEO_RAIN"))
  }

  const setNextValue = async (valueArg: number, maxCount: number = 18) => {
    if (capabilities === undefined || maxCount === 0) return
    if (isPlay) {
      const isValid =
        activeLayers.length !== 0
          ? await WeatheOLayerGroup.checkActiveLayerEmpty(
              activeLayers,
              dateList.current[valueArg],
              capabilities,
              gsmapEmptyMap,
            )
          : true

      if (isValid) {
        setDateWithValue(valueArg)
      } else {
        const nextValue = valueArg + 1
        nextValue > 18 ? setNextValue(0, maxCount - 1) : setNextValue(nextValue, maxCount - 1)
      }
    } else {
      setDateWithValue(valueArg)
    }
  }

  const setDateWithValue = (value: number) => {
    setValue(value)
    setDate(dateList.current[value])
  }

  const handleOpacitiesChange = (name: Layer, opacity: number) => {
    setLayerOpacities(new Map(layerOpacities.set(name, opacity / 100)))
  }

  useEffect(() => {
    ;(async () => {
      try {
        const time = dateList.current.map((date) => WeatheOLayerGroup.dateToLayer(date)).join(",")

        const data = await getWeatheORainCapabilities(time)

        const parser = new WMSCapabilities()
        const capabilities: WMSCapabilitiesJSON = parser.read(data)

        setCapabilities(capabilities)

        const value = findMostCurrentAvailableValue(dateList.current, capabilities)

        if (value) {
          setDateWithValue(value)
          setIsPlay(true)
        }
      } catch (e) {
        captureException(e)
      }

      try {
        setGsmapEmptyMap(await getGsmapEmptyMap(dateList.current))
      } catch (e) {
        captureException(e)
      }
    })()
  }, [])

  const handleClickLayer = (image: Layer) => {
    let newActiveLayers = activeLayers.slice()
    if (activeLayers.includes(image)) newActiveLayers = newActiveLayers.filter((layer) => layer !== image)
    else newActiveLayers = newActiveLayers.concat(image)
    setActiveLayers(newActiveLayers)
    if (newActiveLayers.length === 0) setIsPlay(false)
  }

  return (
    <>
      <WeatherMap
        activeLayers={activeLayers}
        capabilities={capabilities}
        date={date}
        gsmapEmptyMap={gsmapEmptyMap}
        isPlay={isPlay}
        layerOpacities={layerOpacities}
        mapTheme={mapTheme}
      />
      <LayerControl
        activeLayers={activeLayers}
        layerOpacities={layerOpacities}
        onActiveLayersChange={handleClickLayer}
        onLayerOpacitiesChange={handleOpacitiesChange}
      />
      <TimeLine
        dateList={dateList.current}
        isPlay={isPlay}
        value={value}
        onDateChange={setNextValue}
        onPlayChange={handlePlayChange}
      />
      <WeatheOLegendContainer activeLayers={activeLayers} />
      <MapThemeButton mapTheme={mapTheme} onThemeChange={setMapTheme} />
    </>
  )
}

export default Weather

const findMostCurrentAvailableValue = (dateList: Date[], capabilities: WMSCapabilitiesJSON) => {
  for (let value = MAX_VALUE; value > 0; value--) {
    const mostCurrentTime = WeatheOLayerGroup.dateToLayer(dateList[value])
    const mostCurrentLayer = WeatheOLayerGroup.findLayerFromCapabilities(mostCurrentTime, "GEORAIN_INF", capabilities)
    if (mostCurrentLayer !== undefined) return value
  }
}

const getGsmapEmptyMap = async (dateList: Date[]) => {
  const promise = []

  for (let i = 0; i < dateList.length; i += 3) {
    const date = dateList[i]
    const url = getGsmapUrl(date, 0, 0, 0)
    promise.push(getGsMapIsEmpty(url))
  }

  return Promise.all(promise).then((values) => {
    const gsmapEmptyMap = new Map<string, boolean>()

    values.forEach((value) => {
      gsmapEmptyMap.set(value.url, value.result)
    })

    return gsmapEmptyMap
  })
}

const getGsMapIsEmpty = async (url: string): Promise<{ url: string; result: boolean }> => {
  return new Promise((resolve) => {
    const image = new Image()

    image.addEventListener("load", () => {
      if (image.width === 256 && image.height === 256) resolve({ url, result: true })
      else resolve({ url, result: false })
    })

    image.src = url
  })
}
