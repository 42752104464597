import React from "react"

import styles from "./LayerControlPanel.module.scss"
import { WeatheORainLayer, Layer, LayerOpacityMap, MoreLayers, SatelliteLayer } from "./LayerItems"

type Props = {
  activeLayers: Layer[]
  layerOpacities: LayerOpacityMap
  onActiveLayersChange: (image: Layer) => void
  onLayerOpacitiesChange: (name: Layer, opacity: number) => void
}

export default function LayerControl(props: Props) {
  return (
    <div className={styles.LayerControlPanel}>
      <WeatheORainLayer
        activeLayers={props.activeLayers}
        opacities={props.layerOpacities}
        onClick={props.onActiveLayersChange}
        onOpacityChange={props.onLayerOpacitiesChange}
      />
      <SatelliteLayer
        activeLayers={props.activeLayers}
        opacities={props.layerOpacities}
        onClick={props.onActiveLayersChange}
        onOpacityChange={props.onLayerOpacitiesChange}
      />
      <MoreLayers
        activeLayers={props.activeLayers}
        opacities={props.layerOpacities}
        onClick={props.onActiveLayersChange}
        onOpacityChange={props.onLayerOpacitiesChange}
      />
    </div>
  )
}
