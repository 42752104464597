import React from "react"

import { IconBase, IconProps } from "../../IconBase"

export function LayerOutlined(props: IconProps) {
  return (
    <IconBase viewBox={"0 0 24 24"} {...props}>
      <path
        d={
          "M11.99 19.005L4.62 13.275L3 14.535L12 21.535L21 14.535L19.37 13.265L11.99 19.005ZM12 16.465L19.36 10.735L21 9.46503L12 2.46503L3 9.46503L4.63 10.735L12 16.465ZM12 4.99503L17.74 9.46503L12 13.935L6.26 9.46503L12 4.99503Z"
        }
      />
    </IconBase>
  )
}
