import {
  AiPackInfo,
  AiPackInfos,
  AiPackType,
  getAiPacks,
  getScene,
  getSyncStorages,
  InferenceRequestType,
  initAiPackInfo,
  initAiPackInfos,
  initScene,
  initSyncStorage,
  initSyncStorages,
  isError,
  LayerInfoListType,
  Loading,
  PATH_ANALYSIS,
  PATH_BACK,
  postPreviewCredit,
  postInferences,
  postProjects,
  ProjectRequestType,
  ProjectType,
  ProviderType,
  Scene,
  ServiceType,
  SyncStorage,
  SyncStorages,
  isInitialScene,
  getMyAccount,
  initMyAccount,
  MyAccount,
  getMyPermissions,
  isInitialSyncStorage,
  getAiPack,
  getServiceLabel,
  getValidProjectName,
  AREA_AOI_LIMIT,
  generateVectorSourceFromWkb,
  getFormattedArea,
} from "@ovision-gis-frontend/shared"
import AirplaneImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/Airplane.png"
import AutomobileImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/Automobile.png"
import LandCoverImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/LandCover.png"
import MotorwayImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/Motorway.png"
import OfficeBuildingImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/OfficeBuilding.png"
import SEImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/SE.png"
import ShipImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/Ship.png"
import MapboxImg from "@ovision-gis-frontend/shared/src/asset/images/Mapbox.png"
import WaybackImg from "@ovision-gis-frontend/shared/src/asset/images/Wayback.png"
import { captureException } from "@sentry/react"
import {
  ArrowBackOutlined,
  Button,
  CloseOutlined,
  Dropdown,
  IconButton,
  InfoOutlined,
  InputField,
  RadioGroup,
  Switch,
  Toast,
  Tooltip,
} from "@SIAnalytics/ovision-design-system"
import { ItemOption } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/context-menu/ContextMenu"
import { InputFieldBasicStateType } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/text-input/input-field/InputField"
import cn from "classnames"
import { Coordinate } from "ol/coordinate"
import { getBottomRight, getTopLeft } from "ol/extent"
import { WKB } from "ol/format"
import { Polygon } from "ol/geom"
import { toLonLat } from "ol/proj"
import React, { ChangeEventHandler, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { getCloudImg, getCloudName } from "../../common/cloudUtil"
import MyImages from "../my-images/MyImages"
import Panel from "../service-layout/Panel"
import PanelTitle from "../service-layout/PanelTitle"
import AnalysisInquiryModal from "./AnalysisInquiryModal"
import { initWaybackLayer, useAnalysisMapOutletContext } from "./AnalysisMap"
import CloudPathModal from "./CloudPathModal"
import styles from "./CreateProject.module.scss"
import FileItem from "./FileItem"
import SelectBucketModal, { SelectBucketType } from "./SelectBucketModal"
import SourceItem from "./SourceItem"

type AiPackContent = {
  name: string
  type: AiPackType
  src: string
  desc: string
  disabled?: boolean
}
type ProviderContent = {
  name: string
  type: ProviderType
  src: string
  desc: string
}

function CreateProject() {
  const {
    setIsSettingsVisible,
    setSettingsMenu,
    selectedLocalImage,
    setSelectedLocalImage,
    aiPack,
    setAiPack,
    aoiArea,
    setAoiArea,
    aoiPolygon,
    setAoiPolygon,
    project,
    setProject,
    source,
    setSource,
    provider,
    setProvider,
    setProviderUrl,
    waybackLayers,
    waybackLayer,
    setWaybackLayer,
    view,
  } = useAnalysisMapOutletContext()

  const [user, setUser] = useState<MyAccount>(initMyAccount)
  const [aiPackInfos, setAiPackInfos] = useState<AiPackInfos>(initAiPackInfos)
  const [isMyImagesPanelVisible, setIsMyImagesPanelVisible] = useState<boolean>(false)
  const [service, setService] = useState<ServiceType>()
  const [projectName, setProjectName] = useState<string>("")
  const [aiPackList, setAiPackList] = useState<AiPackContent[]>([])
  const [selectedAiPackInfo, setSelectedAiPackInfo] = useState<AiPackInfo>(initAiPackInfo)
  const [isAnalysisInquiryModalVisible, setIsAnalysisInquiryModalVisible] = useState<boolean>(false)
  const [waybackDropdownItems, setWaybackDropdownItems] = useState<ItemOption[]>([])
  const [syncStorages, setSyncStorages] = useState<SyncStorages>(initSyncStorages)
  const [selectedBucket, setSelectedBucket] = useState<SyncStorage>(initSyncStorage)
  const [selectBucketModalType, setSelectBucketModalType] = useState<SelectBucketType>("SOURCE")
  const [isSelectBucketModalVisible, setIsSelectBucketModalVisible] = useState<boolean>(false)
  const [cloudPath, setCloudPath] = useState<string>("")
  const [cloudPathModalTitle, setCloudPathModalTitle] = useState<string>("")
  const [isCloudPathModalVisible, setIsCloudPathModalVisible] = useState<boolean>(false)
  const [cloudPathValidState, setCloudPathValidState] = useState<InputFieldBasicStateType>("default")
  const [isAutoExportResultsActive, setIsAutoExportResultsActive] = useState<boolean>(false)
  const [exportBucket, setExportBucket] = useState<SyncStorage>(initSyncStorage)
  const [requiredCredit, setRequiredCredit] = useState<number>(0)
  const [remainCredit, setRemainCredit] = useState<number>(0)
  const [permissions, setPermissions] = useState<string[]>([])

  const [isSyncStorageFetching, setIsSyncStorageFetching] = useState<boolean>(true)
  const [isAiPackFetching, setIsAiPackFetching] = useState<boolean>(true)
  const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(false)
  const [isInferenceRequesting, setIsInferenceRequesting] = useState<boolean>(false)

  const navigate = useNavigate()
  const { t } = useTranslation()

  const objectDetectionAiPackList: AiPackContent[] = useMemo(
    () => [
      {
        name: t("aiPack.aircraftDetection.title"),
        type: "AircraftDetection",
        src: AirplaneImg,
        desc: t("tooltip.aiPack.aircraftDetection"),
      },
      {
        name: t("aiPack.vesselDetection.title"),
        type: "VesselDetection",
        src: ShipImg,
        desc: t("tooltip.aiPack.vesselDetection"),
      },
      {
        name: t("aiPack.vehicleDetection.title"),
        type: "VehicleDetection",
        src: AutomobileImg,
        desc: t("tooltip.aiPack.vehicleDetection"),
      },
    ],
    [t],
  )
  const infrastructureAiPackList: AiPackContent[] = useMemo(
    () => [
      /*{ @NOTE: Land Cover Seg. Dropped
        name: t("aiPack.landCoverSegmentation.title"),
        type: "LandCoverSegmentation",
        src: LandCoverImg,
        desc: t("tooltip.aiPack.landCoverSegmentation"),
      },*/
      {
        name: t("aiPack.buildingSegmentation.title"),
        type: "BuildingSegmentation",
        src: OfficeBuildingImg,
        desc: t("tooltip.aiPack.buildingSegmentation"),
      },
      {
        name: t("aiPack.roadSegmentation.title"),
        type: "RoadSegmentation",
        src: MotorwayImg,
        desc: t("tooltip.aiPack.roadSegmentation"),
      },
    ],
    [t],
  )
  const imageImprovementAiPackList: AiPackContent[] = useMemo(
    () => [
      {
        name: t("aiPack.superEnhancement.title"),
        type: "SuperEnhancement",
        src: SEImg,
        desc: t("tooltip.aiPack.superEnhancement"),
      },
    ],
    [t],
  )
  const initialAiPackList = useMemo(
    () => [...imageImprovementAiPackList, ...objectDetectionAiPackList, ...infrastructureAiPackList],
    [imageImprovementAiPackList, infrastructureAiPackList, objectDetectionAiPackList],
  )

  const providerList: ProviderContent[] = useMemo(
    () => [
      {
        name: t("analysis.source.mapbox.label"),
        type: "MAPBOX",
        src: MapboxImg,
        desc: t("analysis.source.mapbox.desc"),
      },
      /*{ @NOTE: Wayback Dropped
        name: t("analysis.source.wayback.label"),
        type: "WAYBACK",
        src: WaybackImg,
        desc: t("analysis.source.wayback.desc"),
      },*/
    ],
    [t],
  )

  const isCreditRechargeInfoVisible = useMemo(
    () => requiredCredit - user.creditInfo.remainCredit > 0,
    [requiredCredit, user.creditInfo.remainCredit],
  )
  const isAoiOverTheLimit = useMemo(
    () => source === "PROVIDERS" && aoiArea > (parseInt(t("aiPack.aoi.limit")) || AREA_AOI_LIMIT) / 1e6,
    [aoiArea, source, t],
  )
  const isRunInferenceDisabled = useMemo(
    () =>
      projectName === "" ||
      aiPack === "" ||
      project === "" ||
      !source ||
      (project === "REALTIME" && isInitialSyncStorage(selectedBucket)) ||
      (project === "SINGLE" &&
        ((source === "PROVIDERS" && (service === "IMAGE_IMPROVEMENT" || !provider || !aoiPolygon || isAoiOverTheLimit)) ||
          (source === "MY_IMAGES" && (selectedLocalImage?.id === "-1" || isAoiOverTheLimit)))) ||
      (isAutoExportResultsActive && isInitialSyncStorage(exportBucket)) ||
      isCreditRechargeInfoVisible ||
      isPreviewLoading,
    [
      projectName,
      aiPack,
      project,
      source,
      service,
      provider,
      aoiPolygon,
      isAoiOverTheLimit,
      selectedLocalImage?.id,
      selectedBucket.id,
      isAutoExportResultsActive,
      exportBucket.id,
      isCreditRechargeInfoVisible,
      isPreviewLoading,
    ],
  )

  const aiPackZoomLevel: { [key: string]: number } = {
    "1001": 17,
    "1002": 17,
    "1004": 16,
    "1005": 16,
    "1006": 19,
    "1007": 19,
    "1013": 16, // SuperX
    "1016": 17, // Land Cover Segmentation
  }

  const getLayerInfoList = () => {
    let layerInfoList: LayerInfoListType | undefined
    if (source === "MY_IMAGES") layerInfoList = [{ serviceName: "MY_IMAGES", layer: selectedLocalImage?.id, matrixset: "" }]
    else if (provider === "MAPBOX")
      layerInfoList = [{ serviceName: "MAPBOX", layer: "clb5za3o3000d15lb7bt7nayt", matrixset: "GoogleMapsCompatible" }]
    else if (provider === "WAYBACK")
      layerInfoList = [{ serviceName: "WAYBACK", layer: waybackLayer.identifier, matrixset: "GoogleMapsCompatible" }]

    return layerInfoList
  }

  useEffect(() => {
    const setUserAsync = async () => {
      try {
        const user = await getMyAccount()
        const permissions = await getMyPermissions()
        setPermissions(permissions)
        setUser(user)
      } catch (e) {
        captureException(e)
      }
    }
    const setAiPacksAsync = async () => {
      try {
        const aiPacks = await getAiPacks()
        if (!isError(aiPacks)) setAiPackInfos(aiPacks)
      } catch (e) {
        captureException(e)
      } finally {
        setIsAiPackFetching(false)
      }
    }
    const setProviderUrlAsync = async () => {
      try {
        const _providerUrl = await getScene()
        if (!isError(_providerUrl)) setProviderUrl(_providerUrl)
      } catch (e) {
        captureException(e)
      }
    }

    void setUserAsync()
    void setAiPacksAsync()
    void setProviderUrlAsync()

    return () => {
      setSelectedLocalImage(initScene)
      setAiPack("")
      setProvider(null)
      setWaybackLayer(initWaybackLayer)
      setProject("REALTIME")
    }
  }, [])

  useEffect(() => {
    const setSyncStoragesAsync = async () => {
      try {
        const _syncStorages = await getSyncStorages()
        if (!isError(_syncStorages)) {
          setSyncStorages(_syncStorages)
          const _exportBucket = _syncStorages.syncStorages.find((syncStorage) => syncStorage.status === "SUCCESS")
          if (_exportBucket) {
            setExportBucket(_exportBucket)
          } else {
            setIsAutoExportResultsActive(false)
            setExportBucket(initSyncStorage)
          }
        }
      } catch (error) {
        captureException(error)
      } finally {
        setIsSyncStorageFetching(false)
      }
    }
    void setSyncStoragesAsync()
  }, [selectedBucket])

  useEffect(() => {
    const canNotCalculateCredit = () =>
      !source ||
      (source === "PROVIDERS" && provider === null) ||
      (source === "MY_IMAGES" && isInitialScene(selectedLocalImage)) ||
      project !== "SINGLE"

    const setRequiredCreditAsync = async () => {
      const aoi = aoiPolygon?.clone().transform("EPSG:3857", "EPSG:4326")
      const _aiPackInfo = aiPackInfos.aiPackInfoList.find((_aiPackInfo) => aiPack === _aiPackInfo.type)

      if (canNotCalculateCredit() || !_aiPackInfo) return

      const layerInfoList: LayerInfoListType | undefined = getLayerInfoList()

      let tl: Coordinate = []
      let br: Coordinate = []

      if (aoi) {
        const extent = aoi.getExtent()
        tl = getTopLeft(extent)
        br = getBottomRight(extent)
      } else if (source === "MY_IMAGES" && selectedLocalImage) {
        const extent = generateVectorSourceFromWkb(selectedLocalImage.boundary).getExtent()
        tl = toLonLat(getTopLeft(extent))
        br = toLonLat(getBottomRight(extent))
      } else {
        return
      }

      const data: InferenceRequestType = {
        // @NOTE: jobName이 빈 값일 경우 400 반환, 해당 API의 경우 크레딧 계산하는 API로 jobName의 영향을 받지 않아 임의 설정
        jobName: "preview",
        aipackId: parseInt(_aiPackInfo.id),
        tlLon: tl[0],
        tlLat: tl[1],
        brLon: br[0],
        brLat: br[1],
        layerInfoList: layerInfoList,
        zoom: aiPackZoomLevel[_aiPackInfo.id] ?? 17,
      }
      setIsPreviewLoading(true)
      try {
        const { paidCredit, areaSize } = await postPreviewCredit(data)
        setRequiredCredit(paidCredit)
        setAoiArea(areaSize)
      } catch (e) {
        captureException(e)
      } finally {
        setIsPreviewLoading(false)
      }
    }

    setRequiredCreditAsync()

    return () => {
      setRequiredCredit(0)
    }
  }, [aoiPolygon, aiPack, selectedLocalImage])

  useEffect(() => {
    if (project === "REALTIME" || source !== "MY_IMAGES") setIsMyImagesPanelVisible(false)
    if (project === "SINGLE") {
      setSelectedBucket(initSyncStorage)
      if (source !== "PROVIDERS" && source !== "MY_IMAGES") setSource("PROVIDERS")
    }

    if (service === "IMAGE_IMPROVEMENT") {
      if (project === "SINGLE" && source === "PROVIDERS") setSource("MY_IMAGES")
      setAiPackList(imageImprovementAiPackList)
      setAoiArea(0)
      setAoiPolygon(null)
    } else if (service === "OBJECT_DETECTION") {
      setAiPackList(objectDetectionAiPackList)
    } else if (service === "INFRA") {
      setAiPackList(infrastructureAiPackList)
    } else {
      setAiPackList(initialAiPackList)
    }
  }, [t, project, service, source])

  useEffect(() => {
    if (initialAiPackList.length === aiPackList.length || aiPackList.length === 0) setAiPack("")
    else if (aiPackList.every((_aiPack) => _aiPack.type !== aiPack)) setAiPack(aiPackList[0].type)
  }, [aiPackList])

  useEffect(() => {
    if (!aiPack) return

    const _aiPackInfo = aiPackInfos.aiPackInfoList.find((_aiPackInfo) => _aiPackInfo.type === aiPack)
    if (_aiPackInfo) {
      setSelectedAiPackInfo(_aiPackInfo)
      if (!hasAiPackPermission(permissions, _aiPackInfo.id)) {
        setIsAnalysisInquiryModalVisible(true)
        setAiPack("")
        return
      }
    } else {
      setSelectedAiPackInfo(initAiPackInfo)
      Toast({ message: t("toast.createProject.aiPack.error"), type: "error" })
      return
    }

    if (!service && aiPack) {
      if (imageImprovementAiPackList.some((_aiPack) => _aiPack.type === aiPack)) setService("IMAGE_IMPROVEMENT")
      else if (objectDetectionAiPackList.some((_aiPack) => _aiPack.type === aiPack)) setService("OBJECT_DETECTION")
      else if (infrastructureAiPackList.some((_aiPack) => _aiPack.type === aiPack)) setService("INFRA")
    }
  }, [aiPack, aiPackInfos.aiPackInfoList, permissions])

  useEffect(() => {
    setWaybackDropdownItems(waybackLayers.map((layer) => ({ text: layer.title, value: layer.title })))
    if (waybackLayers.length > 0 && waybackLayer.title === "" && waybackLayer.identifier === "")
      setWaybackLayer(waybackLayers[0])
  }, [waybackLayers])

  const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target
    setProjectName(getValidProjectName(value))
  }

  const onRunInferenceButtonClick = () => {
    const setRemainCreditAsync = async (aoi?: Polygon) => {
      const _aiPackInfo = aiPackInfos.aiPackInfoList.find((_aiPackInfo) => aiPack === _aiPackInfo.type)
      if (!_aiPackInfo) {
        Toast({ message: t("toast.createProject.aiPack.error"), type: "error" })
        setIsInferenceRequesting(false)
        return
      }

      if (!project || (project === "REALTIME" && isInitialSyncStorage(selectedBucket)) || (project === "SINGLE" && !source))
        return
      if (project === "REALTIME") {
        let wkbAoi = null
        if (aoi) {
          const wkbFormat = new WKB()
          wkbAoi = wkbFormat.writeGeometry(aoi)
        }
        if (typeof wkbAoi !== "string" && wkbAoi !== null) return
        if (!selectedBucket.cloudType) return
        const _sourceType = selectedBucket.cloudType === "SIA_CLOUD" ? "GCP" : selectedBucket.cloudType

        const data: ProjectRequestType = {
          name: projectName,
          type: project,
          aoi: _aiPackInfo.id === "1013" ? null : wkbAoi, // SuperX인 경우 null
          aiPackId: parseInt(_aiPackInfo.id),
          source: { id: selectedBucket.id, paths: [selectedBucket.name], type: _sourceType },
          destination: isAutoExportResultsActive ? { id: exportBucket.id, path: exportBucket.name } : undefined,
        }

        try {
          const _project = await postProjects(data)
          if (!isError(_project)) {
            navigate(PATH_ANALYSIS, { replace: true })
            Toast({ message: t("toast.createProject.projectRequested.success"), type: "success" })
          }
        } catch (e) {
          captureException(e)
        } finally {
          setIsInferenceRequesting(false)
        }
      } else if (project === "SINGLE") {
        const layerInfoList: LayerInfoListType | undefined = getLayerInfoList()

        let tl: Coordinate = []
        let br: Coordinate = []
        if (aoi) {
          const extent = aoi.getExtent()
          tl = getTopLeft(extent)
          br = getBottomRight(extent)
        }

        const data: InferenceRequestType = {
          jobName: projectName,
          aipackId: parseInt(_aiPackInfo.id),
          tlLon: tl[0],
          tlLat: tl[1],
          brLon: br[0],
          brLat: br[1],
          layerInfoList: layerInfoList,
          zoom: aiPackZoomLevel[_aiPackInfo.id] ?? 17,
          destination: isAutoExportResultsActive ? { id: exportBucket.id, path: exportBucket.name } : undefined,
        }

        try {
          const _inferences = await postInferences(data)
          if (!isError(_inferences)) {
            setRemainCredit(_inferences.remainCredit)
            navigate(PATH_ANALYSIS, { replace: true })
            Toast({ message: t("toast.createProject.projectRequested.success"), type: "success" })
          }
        } catch (e) {
          captureException(e)
        } finally {
          setIsInferenceRequesting(false)
        }
      }
    }

    if (isRunInferenceDisabled) return
    setIsInferenceRequesting(true)
    setRemainCredit(0)
    void setRemainCreditAsync(aoiPolygon?.clone().transform("EPSG:3857", "EPSG:4326") as Polygon)
  }

  const onImageClick = (image: Scene) => {
    setSelectedLocalImage(image)
    setIsMyImagesPanelVisible(false)
  }

  const onConnectButtonClick = () => {
    setSettingsMenu("Connections")
    setIsSettingsVisible(true)
  }

  return (
    <div className={styles.createProject}>
      <Panel className={styles.panel}>
        <div className={cn(styles.topContainer, isCreditRechargeInfoVisible && styles.creditRechargeInfoWrapperVisible)}>
          <div className={styles.titleContainer}>
            <IconButton
              wrapperClassName={styles.iconBtn}
              size={"small"}
              type={"square"}
              icon={<ArrowBackOutlined />}
              onClick={() => navigate(PATH_BACK)}
            />
            <PanelTitle className={styles.title} label={t("createProject.title")} />
          </div>
          <div className={styles.formContainer}>
            <div className={styles.form}>
              <span className={styles.fieldLabel}>{t("createProject.projectName.label")}</span>
              <InputField
                placeholder={t("createProject.projectName.label") ?? ""}
                value={projectName}
                onChange={onInputChange}
                onClearButtonClick={() => setProjectName("")}
              />
            </div>
            <div className={styles.form}>
              <span className={styles.fieldLabel}>
                {t("createProject.projectType.label")}
                <Tooltip size={"large"} title={t("tooltip.createProject.projectType")} placement={"bottom"} point={true}>
                  <InfoOutlined />
                </Tooltip>
              </span>
              <RadioGroup
                type={"vertical"}
                options={
                  [
                    { label: t("createProject.bucketAnalysis.label"), value: "REALTIME" },
                    { label: t("createProject.singleAnalysis.label"), value: "SINGLE" },
                  ] as { label: string; value: ProjectType }[]
                }
                defaultValue={"REALTIME"}
                onChange={(value) => setProject(value as ProjectType)}
              />
            </div>

            <div className={styles.divider} />
            <div className={styles.form}>
              <span className={styles.fieldLabel}>{t("service.title")}</span>
              <Dropdown
                type={"single"}
                itemList={[
                  { text: t("service.imageImprovement.title"), value: t("service.imageImprovement.title") },
                  { text: t("service.objectDetection.title"), value: t("service.objectDetection.title") },
                  { text: t("service.infrastructure.title"), value: t("service.infrastructure.title") },
                ]}
                placeholder={t("service.title") ?? ""}
                value={getServiceLabel(service)}
                onChange={(value) => {
                  if (value === t("service.imageImprovement.title")) setService("IMAGE_IMPROVEMENT")
                  else if (value === t("service.objectDetection.title")) setService("OBJECT_DETECTION")
                  else if (value === t("service.infrastructure.title")) setService("INFRA")
                }}
              />
            </div>
            <div className={styles.form}>
              <span className={styles.fieldLabel}>{t("aiPack.title")}</span>
              <div className={styles.aiPackContainer}>
                {aiPackList.map((_aiPack) => (
                  <Tooltip key={_aiPack.type} title={<strong>{_aiPack.name}</strong>} placement={"bottom"} point={true}>
                    <div
                      className={cn(
                        styles.aiPackIconWrapper,
                        aiPack === _aiPack.type && styles.active,
                        _aiPack.disabled && styles.disabled,
                      )}
                      onClick={!_aiPack.disabled ? () => setAiPack(_aiPack.type) : undefined}
                    >
                      <img className={styles.img} alt={"IMG"} src={_aiPack.src} />
                    </div>
                  </Tooltip>
                ))}
                {isAiPackFetching && (
                  <div className={styles.loadingContainer}>
                    <Loading size={"small"} />
                  </div>
                )}
              </div>
              {aiPack && (
                <section className={styles.infoBox}>
                  <InfoOutlined />
                  <p>{getAiPack(aiPack).desc}</p>
                </section>
              )}
            </div>

            <div className={styles.divider} />
            <div className={styles.form}>
              <span className={styles.fieldLabel}>
                {t("createProject.source.label")}
                <Tooltip size={"large"} title={t("tooltip.createProject.source")} placement={"bottom"} point={true}>
                  <InfoOutlined />
                </Tooltip>
              </span>
              {project === "REALTIME" && (
                <>
                  {isInitialSyncStorage(selectedBucket) ? (
                    <Button
                      className={styles.selectSourceBtn}
                      size={"large"}
                      type={"secondary"}
                      onClick={() => {
                        setSelectBucketModalType("SOURCE")
                        setIsSelectBucketModalVisible(true)
                      }}
                    >
                      {t("button.selectBuckets")}
                    </Button>
                  ) : (
                    <SourceItem
                      title={selectedBucket.name}
                      description={getCloudName(selectedBucket.cloudType).title}
                      img={getCloudImg(selectedBucket.cloudType)}
                      rightTool={<CloseOutlined />}
                      onRightToolClick={() => setSelectedBucket(initSyncStorage)}
                    />
                  )}
                </>
              )}
              {project === "SINGLE" && (
                <>
                  <div className={styles.tab}>
                    <div
                      className={cn(
                        styles.tabItem,
                        source === "PROVIDERS" && styles.active,
                        service === "IMAGE_IMPROVEMENT" && styles.disabled,
                      )}
                      onClick={service !== "IMAGE_IMPROVEMENT" ? () => setSource("PROVIDERS") : undefined}
                    >
                      {t("providers.title")}
                    </div>
                    <div
                      className={cn(styles.tabItem, source === "MY_IMAGES" && styles.active)}
                      onClick={() => setSource("MY_IMAGES")}
                    >
                      {t("myImages.title")}
                    </div>
                  </div>

                  {source === "PROVIDERS" &&
                    providerList.map((_provider, index) => (
                      <SourceItem
                        className={styles.sourceItem}
                        key={_provider.type + index}
                        title={_provider.name}
                        dropdown={
                          _provider.type === "WAYBACK" && {
                            itemList: waybackDropdownItems,
                            value: waybackLayer.title || (waybackLayers.length > 0 ? waybackLayers[0].title : undefined),
                            placeholder: "YYYY-MM-DD",
                            onChange: (value) => {
                              if (typeof value === "string") {
                                const _layer = waybackLayers.find((_layer) => _layer.title === value)
                                if (_layer) setWaybackLayer(_layer)
                              }
                            },
                          }
                        }
                        active={_provider.type === provider}
                        description={_provider.desc}
                        img={_provider.src}
                        onClick={() => setProvider(_provider.type)}
                      />
                    ))}
                  {source === "MY_IMAGES" &&
                    (selectedLocalImage?.id === "-1" ? (
                      <Button
                        className={styles.selectSourceBtn}
                        size={"large"}
                        type={"secondary"}
                        onClick={() => setIsMyImagesPanelVisible(!isMyImagesPanelVisible)}
                      >
                        {t("button.selectMyImages")}
                      </Button>
                    ) : (
                      <FileItem
                        className={styles.imageItem}
                        files={[
                          {
                            id: selectedLocalImage.id,
                            desc: t("analysis.source.myImages.label") ?? "",
                            contents: [
                              {
                                name: selectedLocalImage.name,
                                contentType: "Image",
                                onDeleteClick: () => setSelectedLocalImage(initScene),
                              },
                            ],
                          },
                        ]}
                      />
                    ))}
                </>
              )}
            </div>

            <div className={styles.divider} />
            <div className={styles.form}>
              <div className={styles.fieldLabelContainer}>
                <span className={styles.fieldLabel}>
                  {t("createProject.autoExportResults.label")}
                  <Tooltip
                    size={"large"}
                    title={t("tooltip.createProject.autoExportResults")}
                    placement={"bottom"}
                    point={true}
                  >
                    <InfoOutlined />
                  </Tooltip>
                </span>
                <div className={styles.switchContainer}>
                  <Switch
                    className={styles.switch}
                    type={"only"}
                    active={isAutoExportResultsActive}
                    disabled={isInitialSyncStorage(exportBucket)}
                    onClick={() => setIsAutoExportResultsActive(!isAutoExportResultsActive)}
                  />
                  {isSyncStorageFetching && <Loading className={styles.loading} size={"xs"} />}
                </div>
              </div>
              {isAutoExportResultsActive && (
                <SourceItem
                  className={styles.sourceItem}
                  rightToolClassName={styles.rightTool}
                  title={exportBucket.name}
                  rightTool={
                    <Button
                      size={"xs"}
                      type={"outlined"}
                      onClick={() => {
                        setSelectBucketModalType("EXPORT")
                        setIsSelectBucketModalVisible(true)
                      }}
                    >
                      {t("button.edit")}
                    </Button>
                  }
                  description={getCloudName(exportBucket.cloudType).title}
                  img={getCloudImg(exportBucket.cloudType)}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.bottomContainer}>
          {isCreditRechargeInfoVisible && (
            <div className={styles.creditRechargeInfoWrapper}>
              <InfoOutlined />
              <div className={styles.content}>
                {t("createProject.creditRechargeGuide.label", { credit: requiredCredit - user.creditInfo.remainCredit })}
              </div>
              {/*TODO: 크레딧 모달창 표시*/}
            </div>
          )}
          <div className={styles.creditWrapper}>
            <div className={styles.content}>
              <span>{t("createProject.area.label")}</span>
              <span className={cn(isAoiOverTheLimit && styles.danger)}>{getFormattedArea(aoiArea)}</span>
            </div>
            <div className={styles.content}>
              <span>{t("createProject.requiredCredit.label")}</span>
              <span className={styles.info}>{requiredCredit || "-"}</span>
            </div>
            <div className={styles.divider} />
            <div className={styles.content}>
              <span>{t("createProject.myCredit.label")}</span>
              <span>{user.creditInfo.remainCredit}</span>
            </div>
          </div>
          <Button
            className={styles.ctaButton}
            size={"xl"}
            type={"cta"}
            disabled={isRunInferenceDisabled}
            loading={isInferenceRequesting}
            onClick={onRunInferenceButtonClick}
          >
            {t("button.createProject")}
          </Button>
        </div>
      </Panel>

      {isMyImagesPanelVisible && (
        <MyImages
          className={styles.myImagesPanel}
          activeImage={selectedLocalImage}
          isPanel={true}
          myImagesPanelVisible={isMyImagesPanelVisible}
          onCloseButtonClick={() => setIsMyImagesPanelVisible(!isMyImagesPanelVisible)}
          onImageClick={onImageClick}
        />
      )}
      {isAnalysisInquiryModalVisible && (
        <AnalysisInquiryModal
          selectedAiPackInfo={selectedAiPackInfo}
          user={user}
          closeModal={() => setIsAnalysisInquiryModalVisible(false)}
        />
      )}
      {isSelectBucketModalVisible && (
        <SelectBucketModal
          type={selectBucketModalType}
          closeModal={() => setIsSelectBucketModalVisible(false)}
          onNewConnectButtonClick={onConnectButtonClick}
          onTableRowClick={(record) =>
            selectBucketModalType === "SOURCE" ? setSelectedBucket(record) : setExportBucket(record)
          }
        />
      )}
      {isCloudPathModalVisible && (
        <CloudPathModal
          title={cloudPathModalTitle}
          input={{
            defaultValue: cloudPath,
            state: cloudPathValidState,
            helperText:
              cloudPathValidState === "error"
                ? t("textInput.helperText.invalid", { target: t("modal.cloudPath.path") })
                : null,
          }}
          closeModal={() => setIsCloudPathModalVisible(false)}
          onConfirmClick={(value) => {
            setCloudPath(value)
            setExportBucket({ ...exportBucket, name: value })
          }}
        />
      )}
    </div>
  )
}
export default CreateProject

const hasAiPackPermission = (permissions: string[], aiPackId: string) => {
  if (aiPackId === "1001") return permissions.includes("permission.inference.aipack.aircraft-detection")
  if (aiPackId === "1002") return permissions.includes("permission.inference.aipack.vessel-detection")
  if (aiPackId === "1004") return permissions.includes("permission.inference.aipack.building-segmentation")
  if (aiPackId === "1005") return permissions.includes("permission.inference.aipack.road-segmentation")
  if (aiPackId === "1006") return permissions.includes("permission.inference.aipack.vehicle-detection")
  if (aiPackId === "1013") return permissions.includes("permission.inference.aipack.super-enhancement")
  if (aiPackId === "1016") return permissions.includes("permission.inference.aipack.land-cover-segmentation")
  return false
}
