import { Map as OlMap } from "ol"
import TileLayer from "ol/layer/Tile"
import { TileWMS } from "ol/source"

import { Layer as LayerType } from "../layer-control-panel/LayerItems"

export type CapabilitiesLayerNameType = "GEORAIN_INF" | "GEORAIN_IR" | "GEORAIN_VI"

class WeatheOLayer {
  private layer
  get getLayer() {
    return this.layer
  }
  private name: LayerType
  get getName() {
    return this.name
  }
  private capabilitiesName: CapabilitiesLayerNameType
  get getCapabilitiesName() {
    return this.capabilitiesName
  }
  public isActive

  constructor(layer: TileLayer<TileWMS>, name: LayerType, capabilitiesName: CapabilitiesLayerNameType, isActive: boolean) {
    this.layer = layer
    this.name = name
    this.capabilitiesName = capabilitiesName
    this.isActive = isActive
  }

  updateOpacity(opacity: number) {
    this.layer.setOpacity(opacity)
  }

  updateLayer(layer: string) {
    this.layer.getSource()?.updateParams({ LAYERS: layer })
  }

  findLayerFromMap(map: OlMap) {
    const hasLayer = map.getAllLayers().find((layer) => this.layer === layer)
    return hasLayer !== undefined
  }

  removeLayer(map: OlMap) {
    const hasLayer = this.findLayerFromMap(map)
    if (hasLayer) map.removeLayer(this.layer)
  }

  addLayer(map: OlMap) {
    const hasLayer = this.findLayerFromMap(map)
    if (!hasLayer) map.addLayer(this.layer)
  }
}

export default WeatheOLayer
