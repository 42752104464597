import {
  Avatar,
  AnalyticsOutlined,
  CreditCardOutlined,
  HelpOutlined,
  OvisionLight,
  PATH_ANALYSIS,
  PATH_MY_IMAGES,
  PhotoFrameOutlined,
  SettingsOutlined,
  URL_DOCS_EN,
  URL_DOCS_KO,
  postSignOut,
  PATH_HOME,
  REFRESH_TOKEN_KEY,
  ACCESS_TOKEN_KEY,
  getMyAccount,
  initMyAccount,
  MyAccount,
  PATH_WEATHEO,
  CloudOutlined,
  useToken,
  PATH_SIGN_IN,
  useMobileWarn,
} from "@ovision-gis-frontend/shared"
import * as Sentry from "@sentry/browser"
import { captureException } from "@sentry/react"
import {
  DoubleArrowLeftOutlined,
  IconButton,
  MenuOutlined,
  TextButton,
  Toast,
  Tooltip,
} from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import i18next from "i18next"
import React, { SetStateAction, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Navigate, NavLink, Outlet, useNavigate, useOutletContext } from "react-router-dom"

import Settings, { SettingsMenu } from "../settings/Settings"
import ContextMenuPopover from "./ContextMenuPopover"
import styles from "./ServiceLayout.module.scss"

// TODO: 전역 상태 관리 라이브러리 적용
export type serviceLayoutOutletContextType = {
  isSettingsVisible: boolean
  setIsSettingsVisible: React.Dispatch<SetStateAction<boolean>>
  settingsMenu: SettingsMenu
  setSettingsMenu: React.Dispatch<SetStateAction<SettingsMenu>>
}
export const useServiceLayoutOutletContextType = () => {
  return useOutletContext<serviceLayoutOutletContextType>()
}

// Reference : https://www.notion.so/sianalytics/74f31bc2848c4fc798117089bcf90ac8
export type ServiceMenuType = "Analysis" | "MyImages" | "Settings" | "Weather"

function ServiceLayout() {
  const [isProfilePopoverVisible, setIsProfilePopoverVisible] = useState<boolean>(false)
  const [isExpandProfilePopoverVisible, setIsExpandProfilePopoverVisible] = useState<boolean>(false)
  const [isHelpPopoverVisible, setIsHelpPopoverVisible] = useState<boolean>(false)
  const [isExpandedHelpPopoverVisible, setIsExpandedHelpPopoverVisible] = useState<boolean>(false)
  const [isSettingsVisible, setIsSettingsVisible] = useState<boolean>(false)
  const [isSideBarExpanded, setIsSideBarExpanded] = useState<boolean>(false)
  const [user, setUser] = useState<MyAccount>(initMyAccount)
  const [settingsMenu, setSettingsMenu] = useState<SettingsMenu>("MyAccount")
  const [serviceMenu, setServiceMenu] = useState<ServiceMenuType>("Analysis")
  const { t } = useTranslation()
  const navigate = useNavigate()
  const activeClassName = styles.active

  useMobileWarn()

  useEffect(() => {
    const setUserAsync = async () => {
      try {
        const user = await getMyAccount()
        setUser(user)
        Sentry.setUser({ email: user.account.profile.email })
      } catch (e) {
        captureException(e)
      }
    }
    void setUserAsync()
  }, [])

  const onSignOutClick = () => {
    const logoutAsync = async () => {
      try {
        await postSignOut()
        localStorage.removeItem(ACCESS_TOKEN_KEY)
        localStorage.removeItem(REFRESH_TOKEN_KEY)
        navigate(PATH_HOME, { replace: true })
      } catch (e) {
        Toast({ message: t("toast.signOut.error"), type: "error" })
        captureException(e)
      }
    }
    void logoutAsync()
  }

  const outletContext = useMemo(
    () => ({
      isSettingsVisible,
      setIsSettingsVisible,
      settingsMenu,
      setSettingsMenu,
    }),
    [isSettingsVisible, settingsMenu],
  )

  const { token } = useToken()
  if (!token) return <Navigate replace={true} to={PATH_SIGN_IN} />

  return (
    <div className={styles.layout}>
      <nav className={cn(styles.sideNavigationBarExpanded, !isSideBarExpanded && styles.animation)}>
        <div className={styles.top}>
          <div className={styles.header}>
            <OvisionLight className={styles.ovisionLogo} />
            <IconButton
              size={"large"}
              type={"square"}
              icon={<DoubleArrowLeftOutlined />}
              onClick={() => setIsSideBarExpanded((prev) => !prev)}
            />
          </div>
          <ContextMenuPopover
            className={styles.profilePopover}
            containerClassName={styles.profileContainer}
            contents={[
              {
                text: t("myAccount.title"),
                onClick: () => {
                  setSettingsMenu("MyAccount")
                  setIsSettingsVisible(true)
                  setIsSideBarExpanded(false)
                },
              },
              { text: t("popover.contextMenu.signOut"), onClick: onSignOutClick },
            ]}
            isVisible={isExpandProfilePopoverVisible}
            setIsVisible={setIsExpandProfilePopoverVisible}
            onClick={() => setIsExpandProfilePopoverVisible((prev) => !prev)}
          >
            <Avatar
              className={styles.profile}
              alt={"IMG"}
              firstName={user.account.profile.givenName}
              profileImage={user.account.profile.picture}
            />
            <div className={styles.profileDetail}>
              <span className={styles.name}>
                {user.account.profile.givenName} {user.account.profile.familyName}
              </span>
              <div className={styles.credit}>
                <CreditCardOutlined />
                <span className={styles.creditText}>{user.creditInfo.remainCredit}</span>
              </div>
            </div>
          </ContextMenuPopover>
          <div className={styles.divider} />
          <div className={styles.navigationMenu}>
            <NavLink className={({ isActive }) => (isActive ? activeClassName : undefined)} to={PATH_WEATHEO}>
              <TextButton
                className={styles.menuButton}
                icon={<CloudOutlined />}
                onClick={() => {
                  setServiceMenu("Weather")
                  setIsSideBarExpanded(false)
                }}
              >
                {t("weather.title")}
              </TextButton>
            </NavLink>
            <NavLink className={({ isActive }) => (isActive ? activeClassName : undefined)} to={PATH_ANALYSIS}>
              <TextButton
                className={styles.menuButton}
                icon={<AnalyticsOutlined />}
                onClick={() => {
                  setServiceMenu("Analysis")
                  setIsSideBarExpanded(false)
                }}
              >
                {t("analysis.title")}
              </TextButton>
            </NavLink>
            <NavLink className={({ isActive }) => (isActive ? activeClassName : undefined)} to={PATH_MY_IMAGES}>
              <TextButton
                className={styles.menuButton}
                icon={<PhotoFrameOutlined />}
                onClick={() => {
                  setServiceMenu("MyImages")
                  setIsSideBarExpanded(false)
                }}
              >
                {t("myImages.title")}
              </TextButton>
            </NavLink>
            <TextButton
              className={styles.menuButton}
              icon={<SettingsOutlined />}
              onClick={() => {
                setIsSettingsVisible(true)
                setIsSideBarExpanded(false)
              }}
            >
              {t("settings.title")}
            </TextButton>
          </div>
        </div>
        <div className={styles.bottom}>
          <ContextMenuPopover
            className={styles.helpPopover}
            containerClassName={styles.helpContainer}
            contents={[
              {
                text: t("contactUs.title"),
                onClick: () => window.openZendeskMessenger(),
              },
              {
                text: t("docs.title"),
                onClick: () => window.open(i18next.language === "ko" ? URL_DOCS_KO : URL_DOCS_EN),
              },
            ]}
            isVisible={isExpandedHelpPopoverVisible}
            setIsVisible={setIsExpandedHelpPopoverVisible}
            onClick={() => setIsExpandedHelpPopoverVisible((prev) => !prev)}
          >
            <TextButton className={styles.menuButton} icon={<HelpOutlined />}>
              {t("help.title")}
            </TextButton>
          </ContextMenuPopover>
        </div>
      </nav>

      <nav className={styles.sideNavigationBar}>
        <div className={styles.top}>
          <div className={styles.header}>
            <IconButton size={"large"} type={"square"} icon={<MenuOutlined />} onClick={() => setIsSideBarExpanded(true)} />
          </div>
          <ContextMenuPopover
            className={styles.profilePopover}
            containerClassName={styles.profileContainer}
            contents={[
              {
                text: t("myAccount.title"),
                onClick: () => {
                  setSettingsMenu("MyAccount")
                  setIsSettingsVisible(true)
                },
              },
              { text: t("popover.contextMenu.signOut"), onClick: onSignOutClick },
            ]}
            isVisible={isProfilePopoverVisible}
            setIsVisible={setIsProfilePopoverVisible}
            onClick={() => setIsProfilePopoverVisible(!isProfilePopoverVisible)}
          >
            <Avatar
              className={styles.profile}
              alt={"IMG"}
              firstName={user.account.profile.givenName}
              profileImage={user.account.profile.picture}
            />
          </ContextMenuPopover>

          <div className={styles.navigationMenu}>
            {/* TODO: Climate 서비스에 사용
              <Tooltip size={"xs"} placement={"left"} point={true} title={<span>{t("home")}</span>}>
                <NavLink to={PATH_SERVICE_HOME} className={({ isActive }) => (isActive ? activeClassName : undefined)}>
                  <IconButton type={"square"} icon={<HomeOutlined />} disabled={true} />
                </NavLink>
              </Tooltip>
            */}
            <Tooltip size={"xs"} title={t("weather.title")} placement={"left"} point={true}>
              <NavLink className={({ isActive }) => (isActive ? activeClassName : undefined)} to={PATH_WEATHEO}>
                <IconButton type={"square"} icon={<CloudOutlined />} onClick={() => setServiceMenu("Weather")} />
              </NavLink>
            </Tooltip>
            <Tooltip size={"xs"} title={t("analysis.title")} placement={"left"} point={true}>
              <NavLink className={({ isActive }) => (isActive ? activeClassName : undefined)} to={PATH_ANALYSIS}>
                <IconButton type={"square"} icon={<AnalyticsOutlined />} onClick={() => setServiceMenu("Analysis")} />
              </NavLink>
            </Tooltip>
            <Tooltip size={"xs"} title={t("myImages.title")} placement={"left"} point={true}>
              <NavLink className={({ isActive }) => (isActive ? activeClassName : undefined)} to={PATH_MY_IMAGES}>
                <IconButton type={"square"} icon={<PhotoFrameOutlined />} onClick={() => setServiceMenu("MyImages")} />
              </NavLink>
            </Tooltip>
            <Tooltip size={"xs"} title={t("settings.title")} placement={"left"} point={true}>
              <IconButton
                type={"square"}
                icon={<SettingsOutlined />}
                onClick={() => {
                  setSettingsMenu("MyAccount")
                  setIsSettingsVisible(true)
                }}
              />
            </Tooltip>
          </div>
        </div>
        <div className={styles.bottom}>
          <ContextMenuPopover
            className={styles.helpPopover}
            containerClassName={styles.helpContainer}
            contents={[
              {
                text: t("contactUs.title"),
                onClick: () => window.openZendeskMessenger(),
              },
              {
                text: t("docs.title"),
                onClick: () => window.open(i18next.language === "ko" ? URL_DOCS_KO : URL_DOCS_EN),
              },
            ]}
            isVisible={isHelpPopoverVisible}
            setIsVisible={setIsHelpPopoverVisible}
            onClick={() => setIsHelpPopoverVisible((prev) => !prev)}
          >
            <IconButton type={"square"} icon={<HelpOutlined />} />
          </ContextMenuPopover>
        </div>
      </nav>

      <Outlet context={outletContext} />
      {isSettingsVisible && (
        <Settings menu={settingsMenu} user={user} onCloseButtonClick={() => setIsSettingsVisible(false)} />
      )}
      {isSideBarExpanded && <div className={styles.dimmed} onClick={() => setIsSideBarExpanded(false)} />}
    </div>
  )
}
export default ServiceLayout
