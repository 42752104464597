import React from "react"

import { IconBase, IconProps } from "../../IconBase"

export function PauseFilled(props: IconProps) {
  return (
    <IconBase viewBox={"0 0 32 32"} {...props}>
      <path d={"M8 25.3334H13.3333V6.66669H8V25.3334ZM18.6667 6.66669V25.3334H24V6.66669H18.6667Z"} />
    </IconBase>
  )
}
