import { Modal } from "@ovision-gis-frontend/shared"
import { Button, InputField } from "@SIAnalytics/ovision-design-system"
import { InputFieldBasicStateType } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/text-input/input-field/InputField"
import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./CloudPathModal.module.scss"

type Props = {
  className?: string
  title?: string
  input?: {
    defaultValue?: string
    state?: InputFieldBasicStateType
    helperText?: string | null
  }
  closeModal: () => void
  onConfirmClick: (v: string) => void
}

function CloudPathModal(props: Props) {
  const [isConfirmRunning, setIsConfirmRunning] = useState<boolean>(false)
  const [value, setValue] = useState<string>(props.input?.defaultValue || "")
  const { t } = useTranslation()

  const onConfirmClick: React.MouseEventHandler<HTMLElement> = (e) => {
    // TODO: Path 유효성 체크
    props.onConfirmClick(value)
    props.closeModal()
  }

  return (
    <Modal
      className={cn(styles.cloudPathModal, props.className)}
      title={props.title ?? ""}
      content={
        <form>
          <label htmlFor={"path"}>{t("modal.cloudPath.desc")}</label>
          <InputField
            id={"path"}
            type={"basic"}
            defaultValue={props.input?.defaultValue}
            helperText={props.input?.helperText ?? ""}
            placeholder={t("modal.cloudPath.path") ?? ""}
            state={props.input?.state ?? "default"}
            onChange={(e) => setValue(e.target.value)}
          />
        </form>
      }
      footer={{
        right: (
          <>
            <Button size={"small"} type={"outlined"} onClick={props.closeModal}>
              {t("button.cancel")}
            </Button>
            <Button size={"small"} type={"primary"} loading={isConfirmRunning} onClick={onConfirmClick}>
              {t("button.confirm")}
            </Button>
          </>
        ),
      }}
      onCloseButtonClick={props.closeModal}
    />
  )
}
export default CloudPathModal
