import { Tooltip, Slider } from "antd"
import { format } from "date-fns"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { formatWith10DigitsOfMinutes } from "./TimeLine"
import styles from "./Track.module.scss"
import "./Track.scss"

function Track(props: { value: number; dateList: Date[]; onValueChange: (value: number) => void }) {
  const [hover, setHover] = useState(false)
  const [hoverValue, setHoverValue] = useState(0)
  const { t } = useTranslation()
  const today = useRef(new Date())
  const sliderRef = useRef<HTMLDivElement>(null)
  const min = 0
  const max = 18

  useEffect(() => {
    const slider = document.getElementsByClassName("ant-slider")[0] as HTMLDivElement | undefined
    const handle = slider?.getElementsByClassName("ant-slider-handle")[0] as HTMLDivElement | undefined
    handle?.removeAttribute("tabindex")

    const handleMouseMove = (e: MouseEvent) => {
      if (!slider) return
      const { width, left } = slider.getBoundingClientRect()
      const { clientX } = e

      const percent = (clientX - left) / width

      const nextValue = percent * max

      const stepValue = Math.round(nextValue)

      // Cut number in case to be like 0.30000000000000004
      const getDecimal = (num: number) => (String(num).split(".")[1] || "").length
      const maxDecimal = Math.max(getDecimal(1), getDecimal(max), getDecimal(min))
      const fixedValue = Number(stepValue.toFixed(maxDecimal))

      const validMax = 18
      if (min > fixedValue || fixedValue > validMax) return
      setHoverValue(fixedValue)
      setHover(true)
    }

    const handleMouseLeave = () => {
      setHover(false)
    }

    slider?.addEventListener("mousemove", handleMouseMove)
    slider?.addEventListener("mouseleave", handleMouseLeave)

    return () => {
      slider?.removeEventListener("mousemove", handleMouseMove)
      slider?.removeEventListener("mouseleave", handleMouseLeave)
    }
  }, [])

  const handleChange = (value: number) => {
    if (value > 18) return

    props.onValueChange(value)
  }

  function getOffset(value: number, min: number, max: number) {
    return (value - min) / (max - min)
  }

  const tooltipOffset = `${getOffset(hoverValue, min, max) * 100}%`

  return (
    <div className={styles.track}>
      <Slider id={"slider"} max={max} min={min} tooltipVisible={false} value={props.value} onChange={handleChange} />
      <div className={styles.tooltipWrapper} ref={sliderRef}>
        <Tooltip
          overlayClassName={styles.tooltip}
          title={formatWith10DigitsOfMinutes(props.dateList[hoverValue], "hh:mm aaa")}
          getPopupContainer={() => sliderRef.current || document.body}
          overlayStyle={{ left: `calc(${tooltipOffset} - 34px)`, pointerEvents: "none" }}
          visible={hover}
        >
          <div className={styles.tooltipChild} style={{ left: tooltipOffset }}></div>
        </Tooltip>
      </div>
      <span className={styles.nowLabel}>{t("now.label")}</span>
      <Tooltip overlayClassName={styles.tooltip} title={t("tooltip.prediction")}>
        <div className={styles.disableArea}></div>
      </Tooltip>
      <datalist className={styles.timeLabels}>
        {props.dateList.map((time, index) => {
          if (Math.floor(time.getMinutes() / 10) * 10 !== 0) return null
          const timeString = format(time, "haaa")
          const isTodayMidnight = timeString === "12am" && time.getDate() === today.current.getDate()
          return (
            <option className={styles.option} key={index} style={{ left: `calc(${5.55556 * index}% - 1px)` }} value={index}>
              {isTodayMidnight ? t("today.label") : timeString}
            </option>
          )
        })}
      </datalist>
    </div>
  )
}

export default Track
