import { MapOutlined } from "@ovision-gis-frontend/shared"
import DarkOSM from "@ovision-gis-frontend/shared/src/asset/images/DarkOSM.png"
import OSM from "@ovision-gis-frontend/shared/src/asset/images/OSM.png"
import SatelliteMap from "@ovision-gis-frontend/shared/src/asset/images/SatelliteMap.png"
import { VerticalButtonGroup, Tooltip } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import Trigger from "rc-trigger"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./MapThemeButton.module.scss"

export type MapThemes = "OSM" | "DarkOSM" | "Satellite"

type Props = { mapTheme: MapThemes; onThemeChange: (theme: MapThemes) => void }

export default function MapThemeButton(props: Props) {
  return (
    <Trigger
      action={["click"]}
      destroyPopupOnHide={true}
      popup={<MapTheme currentTheme={props.mapTheme} onChange={props.onThemeChange} />}
      popupAlign={{ points: ["br", "tr"], offset: [0, -8] }}
      popupStyle={{ width: "fit-content" }}
    >
      <div className={styles.mapBtn}>
        <VerticalButtonGroup size={"large"} options={[{ icon: <MapOutlined />, value: "" }]} />
      </div>
    </Trigger>
  )
}

type MapThemeProps = {
  currentTheme: MapThemes
  onChange: (theme: MapThemes) => void
}

function MapTheme(props: MapThemeProps) {
  const { t } = useTranslation()
  return (
    <div className={styles.mapTheme}>
      <span className={styles.title}>{t("mapTheme.label")}</span>
      <div className={styles.mapOptions}>
        <Tooltip size={"xs"} title={t("button.light")} point={true}>
          <img
            className={cn(props.currentTheme === "OSM" && styles.active)}
            src={OSM}
            onClick={() => props.onChange("OSM")}
          />
        </Tooltip>
        <Tooltip size={"xs"} title={t("button.dark")} point={true}>
          <img
            className={cn(props.currentTheme === "DarkOSM" && styles.active)}
            src={DarkOSM}
            onClick={() => props.onChange("DarkOSM")}
          />
        </Tooltip>
        <Tooltip size={"xs"} title={t("button.satellite")} point={true}>
          <img
            className={cn(props.currentTheme === "Satellite" && styles.active)}
            src={SatelliteMap}
            onClick={() => props.onChange("Satellite")}
          />
        </Tooltip>
      </div>
    </div>
  )
}
