import { LayerOutlined } from "@ovision-gis-frontend/shared"
import GeoCloud from "@ovision-gis-frontend/shared/src/asset/images/GeoCloud.png"
import GeoRain from "@ovision-gis-frontend/shared/src/asset/images/GeoRain.png"
import GSMaP from "@ovision-gis-frontend/shared/src/asset/images/GSMaP.png"
import Satellite from "@ovision-gis-frontend/shared/src/asset/images/Satellite.png"
import { Checkbox, InfoOutlined, Slider, Switch, Tooltip } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import Trigger from "rc-trigger"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./LayerItems.module.scss"

export type Layer = "WEATHEO_RAIN" | "VISIBLE_IMAGE" | "INFRARED_IMAGE" | "GSMAP"
export type LayerOpacityMap = Map<Layer, number>

export function MoreLayers(props: {
  activeLayers: Layer[]
  opacities: LayerOpacityMap
  onClick: (item: Layer) => void
  onOpacityChange: (layer: Layer, opacity: number) => void
}) {
  const { t } = useTranslation()

  return (
    <Trigger
      popup={
        <MoreLayersPanel
          activeItems={props.activeLayers}
          items={[{ name: "GSMaP", value: "GSMAP", src: GSMaP }]}
          opacities={props.opacities}
          onItemSelect={props.onClick}
          onOpacityChange={props.onOpacityChange}
        />
      }
      popupAlign={{
        points: ["bl", "tl"],
        offset: [0, -16],
      }}
      action={["click"]}
      destroyPopupOnHide={true}
      popupStyle={{ width: "fit-content" }}
    >
      <div>
        <LayerItem active={props.activeLayers.includes("GSMAP")} name={t("layers.label")} src={<LayerOutlined />} />
      </div>
    </Trigger>
  )
}

export function SatelliteLayer(props: {
  activeLayers: Layer[]
  opacities: LayerOpacityMap
  onClick: (image: Layer) => void
  onOpacityChange: (layer: Layer, opacity: number) => void
}) {
  const { t } = useTranslation()

  return (
    <Trigger
      popup={
        <SatelliteOpacity
          opacities={props.opacities}
          selectedImage={props.activeLayers}
          onImageSelect={props.onClick}
          onOpacityChange={props.onOpacityChange}
        />
      }
      popupAlign={{
        points: ["bl", "tl"],
        offset: [0, -16],
      }}
      action={["click"]}
      destroyPopupOnHide={true}
      popupStyle={{ width: "fit-content" }}
    >
      <div>
        <LayerItem
          active={props.activeLayers.includes("INFRARED_IMAGE") || props.activeLayers.includes("VISIBLE_IMAGE")}
          name={t("layer.satellite.label")}
          src={Satellite}
        />
      </div>
    </Trigger>
  )
}

export function WeatheORainLayer(props: {
  activeLayers: Layer[]
  opacities: LayerOpacityMap
  onClick: (image: Layer) => void
  onOpacityChange: (layer: Layer, opacity: number) => void
}) {
  const { t } = useTranslation()

  return (
    <Trigger
      popup={
        <WeatheOOpacity
          opacities={props.opacities}
          selectedImage={props.activeLayers}
          onImageSelect={props.onClick}
          onOpacityChange={props.onOpacityChange}
        />
      }
      popupAlign={{
        points: ["bl", "tl"],
        offset: [-14, 0],
      }}
      action={["click"]}
      destroyPopupOnHide={true}
      popupStyle={{ paddingBottom: "16px", width: "fit-content" }}
    >
      <div>
        <LayerItem active={props.activeLayers.includes("WEATHEO_RAIN")} name={t("weatheO.label")} src={GeoRain} />
      </div>
    </Trigger>
  )
}

type LayerItemProps = {
  name: string
  src: React.ReactNode
  disabled?: boolean
  active?: boolean
}

function LayerItem(props: LayerItemProps) {
  return (
    <div className={cn(styles.layerItem, props.active && styles.active, props.disabled && styles.disable)}>
      {typeof props.src === "string" ? (
        <img className={cn(styles.layerImg)} src={props.src}></img>
      ) : (
        <div className={styles.layerIcon}>{props.src}</div>
      )}
      <span className={cn(styles.name)}>{props.name}</span>
    </div>
  )
}

type OpacityProps = {
  selectedImage: Layer[]
  opacities: LayerOpacityMap
  onImageSelect: (image: Layer) => void
  onOpacityChange: (layer: Layer, opacity: number) => void
}

function WeatheOOpacity(props: OpacityProps) {
  const { t } = useTranslation()
  const weatheORainActive = props.selectedImage.includes("WEATHEO_RAIN")
  const weatheORainOpacity = props.opacities.get("WEATHEO_RAIN") || 1

  return (
    <div className={cn(styles.opacityContainer, weatheORainActive && styles.weatheOActive)}>
      <div className={styles.itemContainer}>
        <div className={styles.item}>
          <Checkbox active={weatheORainActive} onChange={() => props.onImageSelect("WEATHEO_RAIN")}>
            {t("rain.label")}
          </Checkbox>
          <Tooltip className={styles.tooltip} size={"xs"} title={t("tooltip.weatheORain")} point={true}>
            <InfoOutlined className={styles.infoIcon} />
          </Tooltip>
        </div>
        {weatheORainActive && (
          <Slider
            type={"zoom"}
            max={100}
            min={0}
            value={Math.floor(weatheORainOpacity * 100)}
            onChange={(value) => props.onOpacityChange("WEATHEO_RAIN", value)}
          />
        )}
      </div>
      <div className={styles.itemContainer}>
        <div className={styles.item}>
          <Checkbox disabled={true}>{t("cloud.label")}</Checkbox>
          <Tooltip className={styles.tooltip} size={"xs"} title={t("comingSoon.label")} point={true}>
            <InfoOutlined className={styles.infoIcon} />
          </Tooltip>
        </div>
        {/* TODO WeatheO Cloud 출시 시 주석 해제*/}
        {/* {WEATHEO_CLOUD && (
            <Slider
              type={"zoom"}
              max={100}
              min={0}
              value={props.opacities.get("WEATHEO_CLOUD")}
              onChange={(value) => props.onOpacityChange("WEATHEO_CLOUD", value)}
            />
          )} */}
      </div>
    </div>
  )
}

type SatelliteOpacityProps = {
  selectedImage: Layer[]
  opacities: LayerOpacityMap
  onImageSelect: (image: Layer) => void
  onOpacityChange: (layer: Layer, opacity: number) => void
}

function SatelliteOpacity(props: SatelliteOpacityProps) {
  const visibleImageActive = props.selectedImage.includes("VISIBLE_IMAGE")
  const infraredImageActive = props.selectedImage.includes("INFRARED_IMAGE")
  const visibleImageOpacity = props.opacities.get("VISIBLE_IMAGE") || 1
  const infraredImageOpacity = props.opacities.get("INFRARED_IMAGE") || 1
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)

  const { t } = useTranslation()

  const handleSelectChange = () => {
    props.onImageSelect("VISIBLE_IMAGE")
    if (!visibleImageActive) {
      setTooltipVisible(true)
      setTimeout(() => setTooltipVisible(false), 3000)
    }
  }

  return (
    <div className={cn(styles.opacityContainer, (visibleImageActive || infraredImageActive) && styles.satelliteActive)}>
      <div className={styles.itemContainer}>
        <div className={styles.item}>
          <Checkbox active={visibleImageActive} onChange={handleSelectChange}>
            {t("visibleImage.label")}
          </Checkbox>
          <Tooltip
            className={styles.tooltip}
            size={"large"}
            title={t("tooltip.visibleImage")}
            point={true}
            visible={tooltipVisible ? tooltipVisible : undefined}
          >
            <InfoOutlined className={styles.infoIcon} />
          </Tooltip>
        </div>
        {visibleImageActive && (
          <Slider
            type={"zoom"}
            max={100}
            min={0}
            value={Math.floor(visibleImageOpacity * 100)}
            onChange={(value) => props.onOpacityChange("VISIBLE_IMAGE", value)}
          />
        )}
      </div>
      <div className={styles.itemContainer}>
        <div className={styles.item}>
          <Checkbox active={infraredImageActive} onChange={() => props.onImageSelect("INFRARED_IMAGE")}>
            {t("infraredImage.label")}
          </Checkbox>
          <Tooltip className={styles.tooltip} size={"xs"} title={t("tooltip.infraredImage")} point={true}>
            <InfoOutlined className={styles.infoIcon} />
          </Tooltip>
        </div>
        {infraredImageActive && (
          <Slider
            type={"zoom"}
            max={100}
            min={0}
            value={Math.floor(infraredImageOpacity * 100)}
            onChange={(value) => props.onOpacityChange("INFRARED_IMAGE", value)}
          />
        )}
      </div>
    </div>
  )
}

type MoreLayersProps = {
  items: { name: string; value: Layer; src: string }[]
  activeItems: Layer[]
  opacities: LayerOpacityMap
  onItemSelect: (item: Layer) => void
  onOpacityChange: (layer: Layer, opacity: number) => void
}

function MoreLayersPanel(props: MoreLayersProps) {
  const { t } = useTranslation()

  return (
    <div className={styles.moreLayers}>
      <h4 className={styles.title}>{t("moreLayers.label")}</h4>
      {props.items.map((item) => {
        return (
          <MoreLayerItem
            key={item.name}
            name={item.name}
            opacities={props.opacities}
            selected={props.activeItems.includes(item.value)}
            src={item.src}
            value={item.value}
            onClick={() => props.onItemSelect(item.value)}
            onOpacityChange={props.onOpacityChange}
          />
        )
      })}
    </div>
  )
}

function MoreLayerItem(props: {
  name: string
  value: Layer
  src: string
  selected: boolean
  opacities: LayerOpacityMap
  onClick: () => void
  onOpacityChange: (layer: Layer, opacity: number) => void
}) {
  const opacity = props.opacities.get(props.value) || 1

  return (
    <div className={styles.moreLayerContainer}>
      <div className={styles.moreLayerItem}>
        <img className={styles.image} src={props.src}></img>
        <span className={styles.name}>{props.name}</span>
        <Switch type={"only"} active={props.selected} onClick={props.onClick} />
      </div>
      {props.selected && (
        <Slider
          type={"zoom"}
          max={100}
          min={0}
          value={Math.floor(opacity * 100)}
          onChange={(value) => props.onOpacityChange(props.value, value)}
        />
      )}
    </div>
  )
}
