import * as Sentry from "@sentry/react"
import React from "react"
import ReactDOM from "react-dom/client"

import "@SIAnalytics/ovision-design-system"
import "ol/ol.css"
import "ol-ext/dist/ol-ext.css"
import "./index.scss"
import "./i18n"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  enabled: process.env.REACT_APP_DOMAIN !== "dev-ovision-gis.com",
  tracesSampleRate: 1.0,
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
