import { PauseFilled, PlayArrowFilled, useIntervalCallback } from "@ovision-gis-frontend/shared"
import { Dropdown } from "@SIAnalytics/ovision-design-system"
import { format } from "date-fns"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./TimeLine.module.scss"
import Track from "./Track"

export type Speed = 1000 | 500 | 250

type Props = {
  isPlay: boolean
  dateList: Date[]
  value: number
  onPlayChange: (isPlay: boolean) => void
  onDateChange: (value: number) => void
}

export const INITIAL_VALUE = 18
export const MAX_VALUE = 18

function TimeLine(props: Props) {
  const [speed, setSpeed] = useState<Speed>(1000)
  const intervalId = useRef<NodeJS.Timer | null>(null)
  const { t } = useTranslation()

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!(document.activeElement instanceof HTMLBodyElement)) return
      if (e.code === "Space") {
        if (props.isPlay) handlePause()
        else handlePlay()
      } else if (e.code === "ArrowLeft") {
        const newValue = props.value - 1
        if (newValue >= 0) props.onDateChange(newValue)
      } else if (e.code === "ArrowRight") {
        const newValue = props.value + 1
        if (newValue <= 18) props.onDateChange(newValue)
      }
    }

    document.body.addEventListener("keydown", handleKeyDown)

    return () => {
      document.body.removeEventListener("keydown", handleKeyDown)
    }
  }, [props.isPlay, props.value, handlePause, handlePlay])

  useEffect(() => {
    if (props.isPlay) play(speed)
    else pause()

    return () => pause()
  }, [props.isPlay])

  function handlePlay() {
    props.onPlayChange(true)
  }

  function handlePause() {
    props.onPlayChange(false)
  }

  const playCallback = useIntervalCallback(
    ((value: number) => () => {
      value += 1
      if (value > 18) {
        value = 0
        props.onDateChange(0)
      } else {
        props.onDateChange(value)
      }
    })(props.value),
  )

  const play = (speed: number) => {
    intervalId.current = setInterval(() => playCallback.current(), speed)
  }

  const pause = () => {
    if (intervalId.current) {
      clearInterval(intervalId.current)
      intervalId.current = null
    }
  }

  const resetInterval = (speed: number) => {
    if (props.isPlay) {
      pause()
      play(speed)
    }
  }

  const handleSpeedChange = (option: string | string[]) => {
    let newSpeed = speed
    switch (option) {
      case "1x":
        newSpeed = 1000
        break
      case "2x":
        newSpeed = 500
        break
      case "4x":
        newSpeed = 250
        break
      default:
        break
    }

    setSpeed(newSpeed)

    resetInterval(newSpeed)
  }

  const handleValueChange = (value: number) => {
    props.onDateChange(value)
    resetInterval(speed)
  }

  return (
    <div className={styles.timeline}>
      <div className={styles.top}>
        {props.isPlay ? (
          <button className={styles.play} onClick={handlePause}>
            <PauseFilled />
          </button>
        ) : (
          <button className={styles.pause} onClick={handlePlay}>
            <PlayArrowFilled />
          </button>
        )}
        <time className={styles.time}>
          {formatWith10DigitsOfMinutes(props.dateList[props.value], "yyyy-MM-dd E hh:mm aaa")}
        </time>
        <div className={styles.dropdown}>
          <span className={styles.label}>{t("speed.label")}</span>
          <Dropdown
            selectClassName={styles.speedSelector}
            type={"single"}
            itemList={[
              { value: "1x", text: "1x" },
              { value: "2x", text: "2x" },
              { value: "4x", text: "4x" },
            ]}
            value={"1x"}
            onChange={handleSpeedChange}
          />
        </div>
      </div>
      <Track dateList={props.dateList} value={props.value} onValueChange={handleValueChange} />
    </div>
  )
}

export default TimeLine

export const getDateList = () => {
  const currentDate = new Date()
  currentDate.setHours(currentDate.getHours() - 3)
  currentDate.setSeconds(0)
  const dateList: Date[] = []
  for (let i = 0; i <= 180; i += 10) {
    const date = new Date(currentDate)
    dateList.push(new Date(date.setMinutes(currentDate.getMinutes() + i)))
  }

  return dateList
}

export const formatWith10DigitsOfMinutes = (date: Date, formatString: string) => {
  const newDate = new Date(date)
  newDate.setMinutes(Math.floor(date.getMinutes() / 10) * 10)

  return format(newDate, formatString)
}
